import RegisterScenario from 'components/RegisterScenario'
import { useLocation } from 'react-router-dom'
import { Button } from 'bumbag'
import { useCancelCustomerBookingMutation } from 'generated'
import { useState } from 'react'
import UnregisterSuccess from 'components/UnregisterSuccess'
import UnregisterError from 'components/UnregisterError'
import FullScreenLoading from 'components/FullScreenLoading'

const Unregister = () => {
  const { hash } = useLocation()
  const [step, setStep] = useState<'preUnrego' | 'postUnrego'>('preUnrego')
  const [bookingId = '', customerId = ''] = hash?.slice(1)?.split(':')
  const [
    cancelCustomerBooking,
    { error, loading },
  ] = useCancelCustomerBookingMutation({
    variables: {
      input: {
        customerId,
        bookingId,
      },
    },
  })
  const unregister = async () => {
    try {
      await cancelCustomerBooking()
    } catch (e) {}
    setStep('postUnrego')
  }

  if (step === 'preUnrego') {
    return (
      <RegisterScenario
        title={'Are you sure you want to unregister from this event?'}
      >
        <>
          Unregistering will mean you will lose your spot on this event's
          registration list.
          <Button marginTop="major-4" onClick={() => unregister()}>
            Yes
          </Button>
        </>
      </RegisterScenario>
    )
  }

  if (loading) return <FullScreenLoading />
  if (error) return <UnregisterError error={error.message} />
  else return <UnregisterSuccess />
}

export default Unregister
