export const S3_IMAGE_DOMAIN_AU =
  process.env.REACT_APP_S3_IMAGE_DOMAIN_AU ||
  'localz-public-assets-develop-ap-southeast-2.s3.ap-southeast-2.amazonaws.com'

export const CDN_IMAGE_DOMAIN_AU =
  process.env.REACT_APP_CDN_IMAGE_DOMAIN_AU || 'dev-public-assets-au.localz.io'

export const S3_IMAGE_DOMAIN_EU =
  process.env.REACT_APP_S3_IMAGE_DOMAIN_AU ||
  'localz-public-assets-develop-eu-west-1.s3.eu-west-1.amazonaws.com'

export const CDN_IMAGE_DOMAIN_EU =
  process.env.REACT_APP_CDN_IMAGE_DOMAIN_AU || 'dev-public-assets-eu.localz.io'

export const S3_IMAGE_DOMAIN_US =
  process.env.REACT_APP_S3_IMAGE_DOMAIN_AU ||
  'localz-public-assets-develop-us-east-2.s3.us-east-2.amazonaws.com'

export const CDN_IMAGE_DOMAIN_US =
  process.env.REACT_APP_CDN_IMAGE_DOMAIN_AU || 'dev-public-assets-us.localz.io'
