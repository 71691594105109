import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Flex, Pagination, Button, styled } from 'bumbag'
import { useHistory, useParams, Link } from 'react-router-dom'
import { get, find, groupBy, entries, chunk, keys } from 'lodash'
import PageWrapper from 'components/PageWrapper'
import {
  SpaceBookingsQuery,
  useSpaceBookingsLazyQuery,
  Site,
  useGetProjectQuery,
} from 'generated'
import BookingTeaser from 'components/BookingTeaser'
import SiteSelect from 'components/SiteSelect'
import FullScreenLoading from 'components/FullScreenLoading'
import { useSitesState } from 'context/Sites'
import RegionSelect from 'components/RegionSelect'
import DatePicker from 'components/DatePicker'
import dayjs from 'dayjs'
import {
  getStoreCodeFromParam,
  projectStoreCodeToStoreCode,
} from 'utils/storeCode'
import { getWorkshopTypeFromParam } from 'utils/urlParams'
import BannerLogo from 'components/logos/BannerLogo'

const MAX_BOOKINGS_PER_PAGE = 5

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: unset;
  &:visited {
    color: #fff;
  }
`

function SizzleBanner({
  isMobile,
  projectId,
  search,
}: {
  isMobile: boolean
  projectId: string
  search: string
}) {
  return (
    <Flex
      width="100%"
      backgroundColor="secondary"
      {...(isMobile
        ? { padding: '1.5rem' }
        : { paddingY: '1rem', paddingX: '2.5rem' })}
      alignItems="center"
      marginBottom="1rem"
    >
      {!isMobile && <BannerLogo />}
      {!isMobile && (
        <Box
          display="flex"
          flexDirection="row"
          marginLeft="1.5rem"
          fontFamily="Futura"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box flexDirection="column" paddingRight="4rem">
            <Box
              color="#fff"
              fontWeight="bold"
              fontSize="calc(15px + 0.390625vw)"
            >
              Enquire about a fundraising Sausage Sizzle
            </Box>
            <Box
              color="#fff"
              fontSize="calc(9px + 0.390625vw)"
              fontFamily="Helvetica Neue"
            >
              The Bunnings sausage sizzle has grown to become both a valued
              fundraising avenue for eligible community groups and an important
              part of the Bunnings experience for our customers.
            </Box>
          </Box>
          <StyledLink to={`/${projectId}/enquiry${search || ''}`}>
            <Button
              palette="secondary"
              alignSelf="flex-end"
              float="right"
              whiteSpace="nowrap"
              border="0.2px solid white"
              fontSize="calc(10px + 0.390625vw)"
            >
              Submit a request
            </Button>
          </StyledLink>
        </Box>
      )}
      {isMobile && (
        <Box
          display="flex"
          flexDirection="column"
          fontFamily="Futura"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box flexDirection="column" marginBottom="1rem">
            <BannerLogo isMobile />
            <Box
              color="#fff"
              fontWeight="bold"
              fontSize="calc(15px + 0.390625vw)"
            >
              Enquire about a fundraising Sausage Sizzle
            </Box>
            <Box
              color="#fff"
              fontSize="calc(9px + 0.390625vw)"
              fontFamily="Helvetica Neue"
            >
              The Bunnings sausage sizzle has grown to become both a valued
              fundraising avenue for eligible community groups and an important
              part of the Bunnings experience for our customers.
            </Box>
          </Box>
          <StyledLink to={`/${projectId}/enquiry${search || ''}`}>
            <Button
              palette="secondary"
              alignSelf="flex-end"
              float="right"
              whiteSpace="nowrap"
              border="0.2px solid white"
              fontSize="calc(10px + 0.390625vw)"
            >
              Submit a request
            </Button>
          </StyledLink>
        </Box>
      )}
    </Flex>
  )
}

function Home() {
  const { projectId } = useParams<{
    projectId: string
  }>()
  const [currentPage, setCurrentPage] = useState(1)

  const [isMobile, setIsMobile] = useState(
    !window.matchMedia('(min-width: 768px)')?.matches
  )
  const checkWindowWidth = () =>
    setIsMobile(!window.matchMedia('(min-width: 768px)')?.matches)

  const search = window.location.search
  const urlParams = new URLSearchParams(search)

  useEffect(() => {
    window.addEventListener('resize', checkWindowWidth)

    return () => {
      window.removeEventListener('resize', checkWindowWidth)
    }
  }, [])

  const storeCode = getStoreCodeFromParam()
  const workshopType = getWorkshopTypeFromParam()

  const startParam = decodeURIComponent(urlParams.get('start') ?? '')
  const region = urlParams.get('region')

  const { push } = useHistory()

  const startDateFilter = useMemo(() => {
    if (startParam) {
      const date = dayjs(startParam)
        .startOf('day')
        .toDate()
      const now = new Date()
      if (date < now) {
        return now
      }
      return date
    }
    return null
  }, [startParam])

  const { sites, loading: sitesLoading, sitesByStoreCode } = useSitesState()

  const regions = useMemo(
    () =>
      sites.reduce((acc, site) => {
        if (site.address?.region) {
          acc.add(site.address?.region)
        }
        return acc
      }, new Set<string>()),
    [sites]
  )

  const [
    getSpaceBookings,
    { data: bookingsData, loading: bookingsLoading },
  ] = useSpaceBookingsLazyQuery()

  const { data: projectData, loading: projectLoading } = useGetProjectQuery({
    variables: { projectId },
  })
  const enableBookingRequests = get(
    projectData,
    'findProject.enableBookingRequests'
  )

  const appendParamToUrl = useCallback(
    (key: string, value?: string | null) => {
      const currentUrlParams = new URLSearchParams(window.location.search)
      if (value) {
        currentUrlParams.set(key, value)
      } else if (currentUrlParams.has(key)) currentUrlParams.delete(key)
      push({
        pathname: `/${projectId}`,
        search: currentUrlParams.toString(),
      })
    },
    [projectId, push]
  )

  useEffect(() => {
    if (!storeCode) return
    const site = sitesByStoreCode[storeCode]
    const newRegion = site?.address?.region
    if (newRegion !== region) appendParamToUrl('region', newRegion)
  }, [appendParamToUrl, region, sitesByStoreCode, storeCode])

  useEffect(() => {
    getSpaceBookings({
      variables: {
        projectId,
        filters: {
          ...(storeCode && { storeCode }),
          start: startDateFilter || dayjs().toDate(),
          ...(workshopType && { eventType: workshopType }),
        },
      },
    })
  }, [getSpaceBookings, projectId, storeCode, startDateFilter, workshopType])

  const bookings = get(
    bookingsData,
    'findBookingsUsingEndDate',
    []
  ) as SpaceBookingsQuery['findBookingsUsingEndDate']

  const filteredBookings = useMemo(
    () =>
      region
        ? bookings?.filter(
            booking =>
              sitesByStoreCode[booking.storeCode!]?.address?.region === region
          )
        : bookings,
    [bookings, region, sitesByStoreCode]
  )

  const bookingsByCampaignAndStore = useMemo(
    () =>
      groupBy(filteredBookings, booking =>
        JSON.stringify({
          campaignId: booking.campaignId,
          storeCode: booking.storeCode,
        })
      ),
    [filteredBookings]
  )

  const bookingChunks = useMemo(
    () => chunk(entries(bookingsByCampaignAndStore), MAX_BOOKINGS_PER_PAGE),
    [bookingsByCampaignAndStore]
  )

  const [currentBookings, setCurrentBookings] = useState(bookingChunks?.[0])

  useEffect(() => {
    setCurrentBookings(bookingChunks[currentPage - 1])
  }, [currentPage, bookingChunks])

  const filteredSites = useMemo(
    () =>
      region ? sites.filter(site => site?.address?.region === region) : sites,
    [region, sites]
  )

  const onSiteSelect = useCallback(
    (selectedSite: Partial<Site> | null) => {
      const storeCode = projectStoreCodeToStoreCode(selectedSite?.storeCode)
      appendParamToUrl('storeCode', storeCode)
      selectedSite && appendParamToUrl('region', selectedSite?.address?.region)
      setCurrentPage(1)
    },
    [appendParamToUrl]
  )

  const onRegionSelect = useCallback(
    (region: string | null) => {
      appendParamToUrl('region', region)
      appendParamToUrl('storeCode', null)
      setCurrentPage(1)
    },
    [appendParamToUrl]
  )

  const onSetDate = useCallback(
    (date: Date | null, type) => {
      appendParamToUrl(type, date?.toISOString())
      setCurrentPage(1)
    },
    [appendParamToUrl]
  )

  if (projectLoading) {
    return <FullScreenLoading />
  }

  return (
    <PageWrapper title="Bunnings">
      {enableBookingRequests && (
        <SizzleBanner
          isMobile={isMobile}
          projectId={projectId}
          search={search}
        />
      )}
      <Flex flexDirection="row" width="100%" flexWrap="wrap">
        <RegionSelect
          regions={[...regions]}
          onSelect={onRegionSelect}
          currentRegion={region}
          loading={sitesLoading}
          containerProps={{
            minWidth: '15rem',
            marginRight: '2rem',
            marginTop: '1rem',
          }}
        />
        <SiteSelect
          onSelect={onSiteSelect}
          sites={filteredSites}
          loading={sitesLoading}
          currentSite={sitesByStoreCode[storeCode || '']}
          containerProps={{
            minWidth: '15rem',
            marginRight: '2rem',
            marginTop: '1rem',
          }}
        />

        <Box maxWidth={'15rem'} marginTop={'1rem'} marginRight={'2rem'}>
          <DatePicker
            value={startDateFilter}
            onChange={(date: Date | [Date, Date] | null) => {
              onSetDate(date as Date | null, 'start')
            }}
            label={'Start Date'}
          />
        </Box>
      </Flex>

      <Box marginTop="major-4">
        {bookingsLoading || sitesLoading ? (
          <FullScreenLoading />
        ) : keys(bookingsByCampaignAndStore).length > 0 ? (
          currentBookings?.map(([key, bookings], i) => {
            const storeCode = JSON.parse(key).storeCode
            return (
              <BookingTeaser
                key={`booking-teaser__${key}`}
                site={find(sites, s => s.storeCode === storeCode)}
                booking={bookings[0]}
                bookings={bookings}
              />
            )
          })
        ) : (
          'There are currently no activities available to book online at this store. Please contact the store directly for more information.'
        )}

        {keys(bookingsByCampaignAndStore).length > 0 && (
          <Pagination
            width="fit-content"
            marginLeft="auto"
            currentPage={currentPage}
            onChangePage={page => {
              setCurrentPage(page)
            }}
            numberOfPages={Math.ceil(
              keys(bookingsByCampaignAndStore).length / MAX_BOOKINGS_PER_PAGE
            )}
          />
        )}
      </Box>
    </PageWrapper>
  )
}

export default Home
