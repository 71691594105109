interface Props {
  capacity: number | undefined | null;
  registeredCount: number | undefined | null;
}

const isFull = ({ capacity, registeredCount }: Props) => {
  const c = capacity || 20;
  const r = registeredCount || 0;

  return c - r <= 0;
};

export default isFull;
