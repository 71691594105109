import {
  storeCodeMappingByStoreCode,
  storeCodeMappingByProjectStoreCode,
} from 'constants/storeMappings'

export const getStoreCodeFromParam = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const storeCode = urlParams.get('storeCode')
  if (!storeCode) return
  return storeCodeMappingByStoreCode[storeCode]?.projectStoreCode
}

export const projectStoreCodeToStoreCode = (
  projectStoreCode?: string | null
) => {
  if (!projectStoreCode) return
  return storeCodeMappingByProjectStoreCode[projectStoreCode].storeCode
}
