// these sizes are arbitrary and you can set them to whatever you wish
import { css } from 'bumbag'

const sizes = {
  giant: 1170,
  desktop: 992,
  tablet: 768,
  phone: 420,
}

type Size = keyof typeof sizes
// iterate through the sizes and create a media template
export const media: { [key in Size]?: any } = (Object.keys(sizes) as Array<
  keyof typeof sizes
>).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16

  // @ts-ignore
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})
