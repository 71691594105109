import React from 'react'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faTimes } from '@fortawesome/fontawesome-pro-regular'
import ReactDatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'

import { Button, InputField, Input, Label, InputFieldProps } from 'bumbag'
export const DatePicker = ({
  value,
  onChange,
  label,
  error,
  isRequired,
  disabled,
  maxWidth,
  inputProps,
  min,
  max,
}: {
  inputProps?: Omit<InputFieldProps, 'after' | 'state' | 'disabled'>
  isRequired?: boolean
  maxWidth?: string
  disabled?: boolean
  error?: string | undefined
  label: string
  value: Date | null
  onChange: (
    date: Date | [Date, Date] | null,
    event?: React.SyntheticEvent<any, Event> | undefined
  ) => void
  min?: Date | null
  max?: Date | null
}) => {
  return (
    <>
      <Label marginBottom={'0.50rem'}>
        {label}{' '}
        {isRequired && (
          <Label display="inline-block" color="danger">
            *
          </Label>
        )}
      </Label>
      <ReactDatePicker
        minDate={min || new Date()}
        maxDate={max}
        selected={value}
        onChange={onChange}
        showMonthDropdown
        showYearDropdown
        dateFormat="dd/MM/yyyy"
        todayButton="Today"
        placeholderText="dd/mm/yyyy"
        disabled={disabled}
        autoComplete="off"
        popperModifiers={{
          flip: {
            enabled: false,
          },
        }}
        customInput={
          <InputField
            {...inputProps}
            maxWidth={maxWidth}
            after={
              <>
                {value ? (
                  <Button
                    margin={'auto'}
                    padding={'0'}
                    variant="ghost"
                    minHeight={'0'}
                    overrides={{
                      Button: {
                        styles: {
                          focus: {
                            boxShadow: 'none',
                          },
                        },
                      },
                    }}
                  >
                    <Input.Icon
                      style={{ fontSize: '0.73rem' }}
                      onClick={(e: any) => {
                        e.stopPropagation()
                        onChange(null)
                      }}
                      // @ts-ignore
                      icon={faTimes}
                      type="font-awesome"
                      color="primary"
                    />
                  </Button>
                ) : (
                  ''
                )}
                <Input.Icon
                  marginRight="major-2"
                  marginLeft="major-1"
                  icon={faCalendar}
                  type="font-awesome"
                  color={'black'}
                />
              </>
            }
            state={error ? 'danger' : undefined}
            disabled={disabled}
          />
        }
      />
    </>
  )
}

export default DatePicker
