import { useMemo } from 'react';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import getEndpoint from 'utils/getEndpoint';

function createApolloClient({ projectId }: { projectId: string }) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: new HttpLink({
      uri: getEndpoint({ projectId })
    }),
    cache: new InMemoryCache()
  });
}

function useApollo({ projectId }: { projectId: string }) {
  return useMemo(() => createApolloClient({ projectId }), [projectId]);
}

export default useApollo;
