import { Overlay, Container, Table, Button, Flex, Text } from 'bumbag'
import { SitesByStoreCode } from 'context/Sites'
import { EnquiryDetails } from '.'

export const ConfirmationModal = ({
  values,
  sitesByStoreCode,
  onCancel,
  onConfirm,
  isSubmitting,
}: {
  values: any
  sitesByStoreCode: SitesByStoreCode
  onCancel: () => void
  onConfirm: (data: EnquiryDetails) => void
  isSubmitting: boolean
}) => {
  return (
    <Overlay
      width="100%"
      height="100%"
      style={{
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container
        display="flex"
        flexDirection="column"
        boxShadow="rgba(0, 0, 0, 0.15) 0px 1px 4px 0px, rgba(0, 0, 0, 0.02) 0px 0px 2px 1px"
        backgroundColor="white"
        borderRadius="6px"
        padding="2rem"
        maxWidth="768px"
      >
        <Text fontSize="400" fontWeight="bold">
          Are these details correct?
        </Text>
        <Text marginBottom="1rem">
          These details will be sent to the Activities Organiser of your
          selected Bunnings store.
        </Text>
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <b>State</b>
              </Table.Cell>
              <Table.Cell>{values.region || '---'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Store</b>
              </Table.Cell>
              <Table.Cell>
                {values.storeCode
                  ? sitesByStoreCode[values.storeCode]?.name
                  : '---'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Name</b>
              </Table.Cell>
              <Table.Cell>{values.contactName || '---'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Email</b>
              </Table.Cell>
              <Table.Cell>{values.vendorEmail || '---'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Mobile Number</b>
              </Table.Cell>
              <Table.Cell>{values.contactNumber || '---'}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Flex flexDirection="row" gap="1rem">
          <Button
            variant="outlined"
            marginTop="1rem"
            width="100%"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            palette="secondary"
            marginTop="1rem"
            width="100%"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            onClick={() => onConfirm(values)}
          >
            Confirm
          </Button>
        </Flex>
      </Container>
    </Overlay>
  )
}
