import { Label, Link as BumbagLink } from 'bumbag'

const Privacy = () => (
  <Label fontWeight="normal" fontSize="100">
    Bunnings is collecting your personal information to process your booking
    request for our D.I.Y workshop as well as for the other purposes set out in
    our Privacy Policy. Bunnings collects and manages your personal information
    in accordance with our{' '}
    <BumbagLink
      href="https://www.bunnings.com.au/privacy-policy"
      // @ts-ignore
      target="_blank"
    >
      Privacy Policy
    </BumbagLink>
    .
  </Label>
)

export default Privacy
