import { useEffect, useState } from 'react'
import { get, filter, map, isEmpty } from 'lodash'
import {
  Text,
  Heading,
  Flex,
  Image as BumbagImage,
  Button,
  styled,
  Box,
  Select,
  Stack,
} from 'bumbag'
import { useParams, Link, useHistory } from 'react-router-dom'
import RegisterForm from 'components/RegisterForm'
import PageWrapper from 'components/PageWrapper'
import { useSpaceBookingQuery } from 'generated'
import FullScreenLoading from 'components/FullScreenLoading'
import NotFound from 'containers/NotFound'
import isFull from 'utils/isFull'
import { formateDateMatch } from 'utils/formatDate'
import { useSitesState } from 'context/Sites'
import { media } from 'utils/style-utils'
import { getUrlParams } from 'utils/urlParams'
import { useEventsState } from '../context/Events'
import formatImageUri from 'utils/formatImageUri'

const Image = styled(BumbagImage)`
  max-width: 100%;
  width: auto;
  height: auto;
`

const Container = styled(Flex)`
  ${media.phone`
    flex-wrap: wrap;
  `};
`

const ImgContainer = styled(Box)`
  width: 50vw;
  margin-right: 2rem;
  ${media.phone`
    width: 100vw;
    margin-right: 0;
    margin-bottom: 1rem;
  `};
`

const DetailsContainer = styled(Stack)`
  width: 50vw;
  ${media.phone`
    width: 100vw;
  `};
`

function Register() {
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const { sitesByStoreCode } = useSitesState()
  const { events } = useEventsState()
  const { bookingId, projectId } = useParams<{
    projectId: string
    bookingId: string
  }>()
  const history = useHistory()

  const { data, refetch: refetchBooking, loading } = useSpaceBookingQuery({
    variables: {
      input: {
        projectId,
        bookingId,
      },
    },
  })

  useEffect(() => {
    refetchBooking()
  }, [refetchBooking, bookingId])

  const booking = get(data, 'findSpaceBooking')

  if (loading) {
    return <FullScreenLoading />
  }

  if (!booking) {
    return <NotFound />
  }

  const full = isFull({
    capacity: booking.capacity,
    registeredCount: booking.registeredCount,
  })
  const allEventsFull =
    events.length > 1
      ? !events.some(
          b =>
            !isFull({
              capacity: b.capacity,
              registeredCount: b.registeredCount,
            })
        )
      : full

  const eventOptions =
    events.length > 1
      ? map(
          filter(events, event => booking.bookingId !== event.bookingId),
          event => ({
            label: formateDateMatch({
              firstDate: event.start!,
              secondDate: event.end!,
              timezone: sitesByStoreCode[event.storeCode || '']?.timezone,
            }),
            value: event.bookingId,
          })
        )
      : null

  const handleEventChange = (event: any) => {
    const bookingId = event.target.value
    if (!bookingId) {
      return
    }
    const url = `/${projectId}/register/${bookingId}${getUrlParams()}`
    history.push(url)
    event.target.selectedIndex = 0 // reset value to placeholder
  }

  const featureImageUri = formatImageUri(
    booking.featureImageUri || 'https://via.placeholder.com/150',
    projectId
  )

  const site = sitesByStoreCode[booking.storeCode || '']

  const siteName = site?.name
  const sitePostCode = site?.attributes.postCode
  const siteAddress = site?.address?.streetAddress
    ? `${site?.address?.streetAddress}, ${siteName}, ${sitePostCode}`
    : `${siteName}, ${sitePostCode}`

  return (
    <PageWrapper
      title={
        (isEmpty(booking.title) ? booking.eventType : booking.title) ||
        'Register'
      }
    >
      <Link
        to={`/${projectId}${getUrlParams()}`}
        style={{ textDecoration: 'none' }}
      >
        <Button variant="link" iconBefore="solid-arrow-left">
          Back
        </Button>
      </Link>
      <Container>
        {/* TODO: probably should have a generic image uri which we get from config-api */}
        <ImgContainer>
          <Image src={featureImageUri} />
        </ImgContainer>
        <DetailsContainer>
          <Heading use="h4">
            {isEmpty(booking.title) ? booking.eventType : booking.title}{' '}
            {allEventsFull ? ' -  Full' : ''}
          </Heading>
          {booking.storeCode && sitesByStoreCode[booking.storeCode] && (
            <Text.Block use="strong">{siteAddress}</Text.Block>
          )}
          {booking.description && (
            <Text.Block paddingTop="major-1" paddingBottom="major-1">
              {booking.description}
            </Text.Block>
          )}
          {booking.start && booking.end && (
            <Text.Block use="strong">
              {formateDateMatch({
                firstDate: booking.start,
                secondDate: booking.end,
                timezone: sitesByStoreCode[booking.storeCode || '']?.timezone,
              })}
            </Text.Block>
          )}
          {eventOptions && !hasSubmitted && (
            <Select
              placeholder={'Select a different date or time'}
              options={eventOptions}
              onChange={handleEventChange}
              value={undefined}
            />
          )}
          {full && !hasSubmitted && (
            <Text.Block>
              {allEventsFull
                ? 'Unfortunately this workshop is booked out.'
                : 'This workshop is booked out for this date and time, however we have spots available on different dates. If you are having trouble booking a spot, please call your local store for assistance.'}
            </Text.Block>
          )}
          {hasSubmitted && (
            <Box marginTop="major-2">
              <Text>
                <b>
                  Thank you! You have successfully registered to a Bunnings
                  event. Please check your email for further details.
                </b>
              </Text>
            </Box>
          )}
          {!full && !hasSubmitted && (
            <RegisterForm setHasSubmitted={setHasSubmitted} booking={booking} />
          )}
        </DetailsContainer>
      </Container>
    </PageWrapper>
  )
}

export default Register
