import React, {
  createContext,
  PropsWithChildren,
  useState,
} from 'react'
import { SpaceBookingsQuery } from '../../generated'
const EventsStateContext = createContext<State | undefined>(undefined)
const EventsActionContext = createContext<Action | undefined>(undefined)

type State = {
  events: SpaceBookingsQuery['findBookingsUsingEndDate']
}

type Action = {
  setEvents: (events: SpaceBookingsQuery['findBookingsUsingEndDate']) => void
}

export const EventsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [events, setEvents] = useState<State['events']>([])

  return (
    <EventsStateContext.Provider
      value={{
        events
      }}
    >
      <EventsActionContext.Provider
        value={{
          setEvents,
        }}
      >
        {children}
      </EventsActionContext.Provider>
    </EventsStateContext.Provider>
  )
}

export const useEventsState = () => {
  const context = React.useContext(EventsStateContext)
  if (context === undefined) {
    throw new Error('useEventsState must be used within a EventsProvider')
  }
  return context
}
export const useEventsActions = () => {
  const context = React.useContext(EventsActionContext)
  if (context === undefined) {
    throw new Error('useEventsAction must be used within a EventsProvider')
  }
  return context
}
