import {
  CDN_IMAGE_DOMAIN_AU,
  CDN_IMAGE_DOMAIN_EU,
  CDN_IMAGE_DOMAIN_US,
  S3_IMAGE_DOMAIN_AU,
  S3_IMAGE_DOMAIN_EU,
  S3_IMAGE_DOMAIN_US,
} from 'constants/domains'
import { REGION_AU, REGION_EU, REGION_US } from './getEndpoint'

function formatImageUri(imageUri: string, projectId: string) {
  const region = projectId.slice(-4)

  if (region === REGION_AU) {
    return imageUri.replace(S3_IMAGE_DOMAIN_AU, CDN_IMAGE_DOMAIN_AU)
  }

  if (region === REGION_EU) {
    return imageUri.replace(S3_IMAGE_DOMAIN_EU, CDN_IMAGE_DOMAIN_EU)
  }

  if (region === REGION_US) {
    return imageUri.replace(S3_IMAGE_DOMAIN_US, CDN_IMAGE_DOMAIN_US)
  }

  return imageUri.replace(S3_IMAGE_DOMAIN_AU, CDN_IMAGE_DOMAIN_AU)
}

export default formatImageUri
