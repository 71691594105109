import { Spinner, Box } from 'bumbag';

function FullScreenLoading() {
  return (
    <Box
      data-testid="loading"
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      display="flex"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Box>
  );
}

export default FullScreenLoading;
