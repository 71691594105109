export const PAY_SERVER_AU =
  process.env.REACT_APP_PAY_SERVER_AU ||
  'https://dev-api-au.localz.io/pay/graphql'

export const PAY_SERVER_EU =
  process.env.REACT_APP_PAY_SERVER_AU ||
  'https://dev-api-au.localz.io/pay/graphql'

export const PAY_SERVER_US =
  process.env.REACT_APP_PAY_SERVER_AU ||
  'https://dev-api-au.localz.io/pay/graphql'
