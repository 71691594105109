import React from 'react'
import { Box, Alert } from 'bumbag'
import RegisterScenario from './RegisterScenario'

const toMessage = (errorMsg: string) => {
  switch (errorMsg) {
    case 'Booking not found':
      return 'This event no longer exists. You do not need to unregister to this event.'
    case 'Customer is not associated with booking':
      return 'You have already cancelled your registration to this event.'
    default:
      return 'An error occurred while completing your unregistration. Please try again.'
  }
}
const UnregisterError = ({ error }: { error: string }) => {
  return (
    <RegisterScenario title={'Unregistration Error'}>
      <Box marginBottom="major-4">
        <Alert variant="tint" border="0" type="danger" hasIcon>
          {toMessage(error)}
        </Alert>
      </Box>
    </RegisterScenario>
  )
}
export default UnregisterError
