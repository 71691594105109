import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(utc)
dayjs.extend(timezone)

//https://github.com/iamkun/dayjs/blob/master/docs/en/Plugin.md#advancedformat
dayjs.extend(advancedFormat)

function formatDate(date: Date, timezone?: string | null) {
  const tz = timezone || 'Australia/Melbourne'
  return dayjs(date)
    .tz(tz)
    .format('h:mma, dddd D MMMM YYYY')
}

export function formateDateMatch({
  firstDate,
  secondDate,
  timezone,
}: {
  firstDate: Date
  secondDate: Date
  timezone?: string | null
}) {
  const tz = timezone || 'Australia/Melbourne'
  const first = dayjs(firstDate)
    .tz(tz)
    .format('h:mmA')
  const second = dayjs(secondDate)
    .tz(tz)
    .format('h:mmA')

  return `${first} - ${second}, ${dayjs(firstDate)
    .tz(tz)
    .format('dddd Do [of] MMMM YYYY')}`
}

export default formatDate
