import { useCallback } from 'react'
import { sortBy, filter, find } from 'lodash'
import { SelectMenu, Label, Box, BoxProps, LabelProps } from 'bumbag'
import { Site } from 'generated'
import { StyledAsterisk } from './RegionSelect'

type Option = { key: string; label: string; value: string }

function SiteSelect({
  currentSite,
  onSelect,
  sites,
  loading,
  containerProps,
  state,
  required,
  labelProps,
  disableClear,
}: {
  currentSite: Partial<Site> | null
  onSelect: Function
  sites: readonly Site[]
  loading: boolean
  containerProps?: BoxProps
  state?: string
  required?: boolean
  labelProps?: LabelProps
  disableClear?: boolean
}) {
  const loadOptions = useCallback(
    async ({
      searchText,
      variables,
    }: {
      searchText?: string
      variables?: any
    }) => {
      const { sitesVariable } = variables
      const sortedSites = sortBy(sitesVariable, 'name')
      const allOptions = sortedSites.map(site => ({
        key: site.storeCode,
        label: site.name,
        value: site.storeCode,
      })) as Option[]
      const searchRegExp = new RegExp(searchText!, 'i')
      const fuzzyFind = (option: any) => searchRegExp.test(option.label)
      const searchFilteredOptions = filter(allOptions, fuzzyFind)
      return { options: searchFilteredOptions }
    },
    []
  )

  const onChange = (option: Option) => {
    if (!option) {
      return onSelect(null)
    }
    const site = find(sites, ['storeCode', option.key])
    onSelect({
      storeCode: option.key,
      name: option.label,
      address: site?.address,
    })
  }

  return (
    <Box {...containerProps}>
      <Label htmlFor="select-location" {...labelProps}>
        Store {required && <StyledAsterisk>*</StyledAsterisk>}
      </Label>
      <SelectMenu
        defer
        cacheKey="sites"
        id="select-location"
        hasSearch
        disableClear={disableClear}
        label="Store"
        loadVariables={{ sitesVariable: sites }}
        onChange={(e: any) => onChange(e)}
        loadOptions={loadOptions}
        placeholder="Select store"
        // @ts-ignore
        value={
          currentSite && {
            key: currentSite.siteId,
            value: currentSite.storeCode,
            label: currentSite.name,
          }
        }
        isLoading={loading}
        state={state}
      />
    </Box>
  )
}

export default SiteSelect
