import { Site, SitesQueryVariables, useSitesLazyQuery } from 'generated'
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import { ApolloError, QueryLazyOptions } from '@apollo/client'
const SitesStateContext = createContext<State | undefined>(undefined)
const SitesActionContext = createContext<Action | undefined>(undefined)

type State = {
  sites: readonly Site[]
  sitesByStoreCode: SitesByStoreCode
  loading: boolean
  error?: ApolloError
}

type Action = {
  getSites: (
    options?: QueryLazyOptions<SitesQueryVariables> | undefined
  ) => void
}

export type SitesByStoreCode = {
  [key: string]: Site
}

export const SitesProvider = ({ children }: PropsWithChildren<{}>) => {
  const { projectId } = useParams<{ projectId: string }>()
  const [sites, setSites] = useState<State['sites']>([])
  const [sitesByStoreCode, setSitesByStoreCode] = useState<
    State['sitesByStoreCode']
  >({})

  const [getSites, { data: sitesData, loading, error }] = useSitesLazyQuery({
    variables: {
      projectId,
    },
  })

  useEffect(() => {
    getSites()
  }, [getSites, projectId])

  useEffect(() => {
    const sites: readonly Site[] = get(sitesData, 'sites') || []
    const sitesByStoreCode: SitesByStoreCode = {}
    for (const site of sites) {
      if (site.storeCode) {
        sitesByStoreCode[site.storeCode] = site
      }
    }
    setSites(sites)
    setSitesByStoreCode(sitesByStoreCode)
  }, [sitesData])

  return (
    <SitesStateContext.Provider
      value={{
        sites,
        sitesByStoreCode,
        loading,
        error,
      }}
    >
      <SitesActionContext.Provider
        value={{
          getSites,
        }}
      >
        {children}
      </SitesActionContext.Provider>
    </SitesStateContext.Provider>
  )
}

export const useSitesState = () => {
  const context = React.useContext(SitesStateContext)
  if (context === undefined) {
    throw new Error('useSitesState must be used within a SitesProvider')
  }
  return context
}
export const useSitesActions = () => {
  const context = React.useContext(SitesActionContext)
  if (context === undefined) {
    throw new Error('useSitesAction must be used within a SitesProvider')
  }
  return context
}
