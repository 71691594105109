import { useParams, Link } from 'react-router-dom';
import { Heading, Text, Image, Flex } from 'bumbag';

function NotFound() {
  const { projectId } = useParams<{ projectId?: string }>();
  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <Image
        maxWidth="200px"
        src="https://platform.localz.io/cones.png"
        alt="Not found"
      />
      <Heading>404</Heading>
      <Text>The page you are looking for could not be found</Text>
      {projectId && <Link to={`/${projectId}`}>HOME</Link>}
    </Flex>
  );
}

export default NotFound;
