// THIS IS A GENERATED FILE, use `yarn codegen to regenerate
/* tslint:disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;


/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: Date,
  JSONObject: any,
};

export type Query = {
  readonly __typename?: 'Query',
  readonly findSpaceBookings: ReadonlyArray<SpaceBookingOutput>,
  readonly findBookingsUsingEndDate: ReadonlyArray<SpaceBookingOutput>,
  readonly findSpaceBooking: SpaceBookingOutput,
  readonly bookings: FindBookingsResult,
  readonly vendors: VendorsQueryResult,
  readonly bookingRequests: FindBookingRequestsResult,
  readonly findProject?: Maybe<Project>,
  readonly findProducts?: Maybe<StripeProductsResponse>,
  readonly findPrices?: Maybe<StripePricesResponse>,
  readonly vendor?: Maybe<Vendor>,
  readonly findVendors?: Maybe<ReadonlyArray<Maybe<Vendor>>>,
  readonly findBalance?: Maybe<Balance>,
  readonly findSite?: Maybe<Site>,
  readonly sites?: Maybe<ReadonlyArray<Site>>,
  readonly findBookingsForToday?: Maybe<ReadonlyArray<Maybe<Booking>>>,
  readonly findBookings?: Maybe<ReadonlyArray<Maybe<Booking>>>,
  readonly findBooking?: Maybe<Booking>,
  readonly findBookingInvitation?: Maybe<Invitation>,
  readonly findUpcomingBookings?: Maybe<ReadonlyArray<Maybe<Booking>>>,
  readonly bookingsByStore?: Maybe<ReadonlyArray<Maybe<Booking>>>,
  readonly findOrder?: Maybe<Order>,
  readonly findOrders?: Maybe<ReadonlyArray<Maybe<Order>>>,
  readonly findCampaigns?: Maybe<FindCampaignsResponse>,
};


export type QueryFindSpaceBookingsArgs = {
  filters?: Maybe<SpaceBookingInput>,
  projectId: Scalars['ID']
};


export type QueryFindBookingsUsingEndDateArgs = {
  filters?: Maybe<SpaceBookingInput>,
  projectId: Scalars['ID']
};


export type QueryFindSpaceBookingArgs = {
  input: SpaceBookingGetInput
};


export type QueryBookingsArgs = {
  input: BookingsQueryInput
};


export type QueryVendorsArgs = {
  input: FindVendorsInput
};


export type QueryBookingRequestsArgs = {
  input: BookingRequestQueryInput
};


export type QueryFindProjectArgs = {
  projectId: Scalars['ID'],
  subProjectId?: Maybe<Scalars['String']>
};


export type QueryFindProductsArgs = {
  projectId: Scalars['ID']
};


export type QueryFindPricesArgs = {
  projectId: Scalars['ID']
};


export type QueryVendorArgs = {
  projectId: Scalars['ID'],
  query: FindVendorInput
};


export type QueryFindVendorsArgs = {
  projectId: Scalars['ID'],
  projectSecretKey?: Maybe<Scalars['String']>,
  search?: Maybe<Scalars['String']>
};


export type QueryFindSiteArgs = {
  projectId: Scalars['ID'],
  storeCode: Scalars['ID']
};


export type QuerySitesArgs = {
  projectId: Scalars['ID'],
  level?: Maybe<Scalars['Int']>
};


export type QueryFindBookingsForTodayArgs = {
  projectId: Scalars['ID'],
  storeCode: Scalars['ID']
};


export type QueryFindBookingsArgs = {
  projectId: Scalars['ID'],
  storeCode: Scalars['String'],
  startDate?: Maybe<Scalars['DateTime']>,
  endDate?: Maybe<Scalars['DateTime']>
};


export type QueryFindBookingArgs = {
  input?: Maybe<FindBookingInput>
};


export type QueryFindBookingInvitationArgs = {
  bookingId: Scalars['ID'],
  invitationCode: Scalars['String']
};


export type QueryFindUpcomingBookingsArgs = {
  input?: Maybe<FindUpcomingBookingsInput>
};


export type QueryBookingsByStoreArgs = {
  input?: Maybe<BookingsByStoreInput>
};


export type QueryFindOrderArgs = {
  projectId: Scalars['ID'],
  bookingId: Scalars['ID'],
  orderNumber: Scalars['ID']
};


export type QueryFindCampaignsArgs = {
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  start?: Maybe<Scalars['String']>,
  end?: Maybe<Scalars['String']>,
  filters?: Maybe<CampaignGetInput>
};

export type SpaceBookingOutput = {
  readonly __typename?: 'SpaceBookingOutput',
  readonly bookingId?: Maybe<Scalars['ID']>,
  readonly projectId?: Maybe<Scalars['ID']>,
  readonly storeCode?: Maybe<Scalars['String']>,
  readonly eventType?: Maybe<Scalars['String']>,
  readonly themeType?: Maybe<Scalars['String']>,
  readonly start?: Maybe<Scalars['DateTime']>,
  readonly end?: Maybe<Scalars['DateTime']>,
  readonly kind?: Maybe<Scalars['String']>,
  readonly campaignId?: Maybe<Scalars['ID']>,
  readonly capacity?: Maybe<Scalars['Float']>,
  readonly registeredCount?: Maybe<Scalars['Float']>,
  readonly title?: Maybe<Scalars['String']>,
  readonly description?: Maybe<Scalars['String']>,
  readonly lastUpdatedBy?: Maybe<Scalars['String']>,
  readonly updatedAt?: Maybe<Scalars['DateTime']>,
  readonly createdAt?: Maybe<Scalars['DateTime']>,
  readonly featureImageUri?: Maybe<Scalars['String']>,
  readonly customers?: Maybe<ReadonlyArray<CustomerOutput>>,
  readonly deleteReason?: Maybe<Scalars['String']>,
  readonly confirmedCustomerSlices?: Maybe<ReadonlyArray<CustomerSliceOutput>>,
  readonly waitlistedCustomerSlices?: Maybe<ReadonlyArray<CustomerSliceOutput>>,
  readonly templateType?: Maybe<TemplateType>,
};


export type SpaceBookingOutputCustomersArgs = {
  filters?: Maybe<CustomerFilterInput>
};


export type CustomerOutput = {
  readonly __typename?: 'CustomerOutput',
  readonly status?: Maybe<Scalars['String']>,
  readonly name?: Maybe<Scalars['String']>,
  readonly optIn?: Maybe<Scalars['Boolean']>,
  readonly email?: Maybe<Scalars['String']>,
  readonly tel?: Maybe<Scalars['String']>,
  readonly customerId?: Maybe<Scalars['ID']>,
};

export type CustomerFilterInput = {
  readonly status?: Maybe<CustomerStatus>,
  readonly name?: Maybe<Scalars['String']>,
  readonly optIn?: Maybe<Scalars['Boolean']>,
};

export enum CustomerStatus {
  CONFIRMED = 'CONFIRMED',
  WAITING_LIST = 'WAITING_LIST'
}

export type CustomerSliceOutput = {
  readonly __typename?: 'CustomerSliceOutput',
  readonly customerId?: Maybe<Scalars['ID']>,
  readonly addAdults?: Maybe<Scalars['Float']>,
  readonly addChildren?: Maybe<Scalars['Float']>,
};

export enum TemplateType {
  MARKETING = 'MARKETING',
  ADDITIONAL = 'ADDITIONAL'
}

export type SpaceBookingInput = {
  readonly bookingId?: Maybe<Scalars['ID']>,
  readonly storeCode?: Maybe<Scalars['ID']>,
  readonly start?: Maybe<Scalars['DateTime']>,
  readonly end?: Maybe<Scalars['DateTime']>,
  readonly description?: Maybe<Scalars['String']>,
  readonly capacity?: Maybe<Scalars['Float']>,
  readonly deleteReason?: Maybe<Scalars['String']>,
  readonly eventType?: Maybe<Scalars['String']>,
  readonly themeType?: Maybe<Scalars['String']>,
  readonly templateType?: Maybe<TemplateType>,
};

export type SpaceBookingGetInput = {
  readonly projectId: Scalars['ID'],
  readonly bookingId: Scalars['ID'],
};

export type FindBookingsResult = {
  readonly __typename?: 'FindBookingsResult',
  readonly items: ReadonlyArray<Booking>,
  readonly count: Scalars['Float'],
  readonly pages: Scalars['Float'],
  readonly currentPage: Scalars['Float'],
};

export type Booking = {
  readonly __typename?: 'Booking',
  readonly bookingId: Scalars['ID'],
  readonly projectId: Scalars['ID'],
  readonly storeCode: Scalars['ID'],
  readonly vendorId: Scalars['ID'],
  readonly vendor: Vendor,
  readonly lastUpdated: Scalars['DateTime'],
  readonly status?: Maybe<BookingStatus>,
  readonly invitationCode: Scalars['String'],
  readonly eventType?: Maybe<Scalars['String']>,
  readonly name?: Maybe<Scalars['String']>,
  readonly summary: BookingSummary,
  readonly donationsEnabled?: Maybe<Scalars['Boolean']>,
  readonly isRedRun?: Maybe<Scalars['Boolean']>,
  readonly purchaseCap?: Maybe<Scalars['Int']>,
  readonly taxId?: Maybe<TaxId>,
  readonly declineReason?: Maybe<BookingDeclineReason>,
  readonly start?: Maybe<Scalars['DateTime']>,
  readonly end?: Maybe<Scalars['DateTime']>,
  readonly productIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
};

export type Vendor = {
  readonly __typename?: 'Vendor',
  readonly vendorId: Scalars['ID'],
  readonly projectId: Scalars['ID'],
  readonly subProjectId?: Maybe<Scalars['String']>,
  readonly productIds?: Maybe<ReadonlyArray<Scalars['String']>>,
  readonly email?: Maybe<Scalars['String']>,
  readonly name: Scalars['String'],
  readonly registeredStoreCode?: Maybe<Scalars['String']>,
  readonly verificationStatus?: Maybe<VendorVerificationStatus>,
  readonly lastUpdated?: Maybe<Scalars['DateTime']>,
  readonly contactName?: Maybe<Scalars['String']>,
  readonly stripeDetails?: Maybe<Scalars['JSONObject']>,
  readonly altName?: Maybe<Scalars['String']>,
  readonly taxId?: Maybe<TaxId>,
  readonly isCharity?: Maybe<Scalars['Boolean']>,
  readonly upcomingBookingsCount: Scalars['Float'],
  readonly pendingBookingRequest?: Maybe<BookingRequest>,
  readonly bookings?: Maybe<ReadonlyArray<Maybe<Booking>>>,
};


export type VendorBookingsArgs = {
  filters?: Maybe<BookingFilterInput>
};

export enum VendorVerificationStatus {
  VERIFIED = 'verified',
  PENDING = 'pending',
  UNVERIFIED = 'unverified',
  BOOKINGREQUEST = 'bookingRequest'
}


export type TaxId = {
  readonly __typename?: 'TaxId',
  readonly type: TaxIdType,
  readonly value: Scalars['String'],
};

export enum TaxIdType {
  AU_ABN = 'au_abn',
  NZ_NZBN = 'nz_nzbn'
}

export type BookingRequest = {
  readonly __typename?: 'BookingRequest',
  readonly bookingRequestId: Scalars['ID'],
  readonly projectId: Scalars['ID'],
  readonly vendorId: Scalars['ID'],
  readonly vendorName: Scalars['ID'],
  readonly vendorAltName?: Maybe<Scalars['String']>,
  readonly contactName: Scalars['String'],
  readonly contactNumber: Scalars['String'],
  readonly datePreferences: ReadonlyArray<Scalars['DateTime']>,
  readonly comments?: Maybe<Scalars['String']>,
  readonly storeCode: Scalars['String'],
  readonly status: BookingRequestStatus,
  readonly triggeredVendorCreation?: Maybe<Scalars['Boolean']>,
  readonly vendor: Vendor,
  readonly createdAt: Scalars['DateTime'],
};

export enum BookingRequestStatus {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  DISMISSED = 'DISMISSED',
  REJECTED = 'REJECTED'
}

export enum BookingStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED'
}

export type BookingSummary = {
  readonly __typename?: 'BookingSummary',
  readonly items: ReadonlyArray<BookingSummaryItem>,
  readonly currency?: Maybe<CurrencyCode>,
  readonly sales?: Maybe<Scalars['Int']>,
  readonly refunded?: Maybe<Scalars['Int']>,
  readonly netTurnover?: Maybe<Scalars['Int']>,
  readonly orderCount?: Maybe<Scalars['Int']>,
  readonly refundCount?: Maybe<Scalars['Int']>,
};

export type BookingSummaryItem = {
  readonly __typename?: 'BookingSummaryItem',
  readonly name: Scalars['String'],
  readonly quantity: Scalars['Int'],
  readonly total: Scalars['Int'],
};

export type BookingDeclineReason = {
  readonly __typename?: 'BookingDeclineReason',
  readonly reason: Scalars['String'],
  readonly additionalInfo: Scalars['String'],
};

export type BookingsQueryInput = {
  readonly storeCode?: Maybe<Scalars['String']>,
  readonly vendorId?: Maybe<Scalars['String']>,
  readonly date?: Maybe<BookingDateFilter>,
  readonly timezone?: Maybe<Scalars['String']>,
  readonly status?: Maybe<BookingStatus>,
  readonly search?: Maybe<Scalars['String']>,
  readonly pagination?: Maybe<PaginationInput>,
};

export enum BookingDateFilter {
  ALL = 'ALL',
  FUTURE = 'FUTURE',
  PAST = 'PAST',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  YESTERDAY = 'YESTERDAY',
  THIS_WEEK = 'THIS_WEEK'
}

export type PaginationInput = {
  readonly limit: Scalars['Float'],
  readonly offset: Scalars['Float'],
};

export type VendorsQueryResult = {
  readonly __typename?: 'vendorsQueryResult',
  readonly items: ReadonlyArray<Vendor>,
  readonly count: Scalars['Float'],
  readonly pages: Scalars['Float'],
  readonly currentPage: Scalars['Float'],
};

export type FindVendorsInput = {
  readonly storeCode?: Maybe<Scalars['String']>,
  readonly verificationStatus?: Maybe<VendorVerificationStatus>,
  readonly search?: Maybe<Scalars['String']>,
  readonly pagination?: Maybe<FindVendorsPaginationInput>,
};

export type FindVendorsPaginationInput = {
  readonly limit: Scalars['Float'],
  readonly offset: Scalars['Float'],
};

export type FindBookingRequestsResult = {
  readonly __typename?: 'FindBookingRequestsResult',
  readonly items: ReadonlyArray<BookingRequest>,
  readonly count: Scalars['Float'],
  readonly pages: Scalars['Float'],
  readonly currentPage: Scalars['Float'],
};

export type BookingRequestQueryInput = {
  readonly storeCode?: Maybe<Scalars['String']>,
  readonly status?: Maybe<ReadonlyArray<BookingRequestStatus>>,
  readonly pagination?: Maybe<PaginationInput>,
};

export type Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateSpaceBooking: SpaceBookingOutput,
  readonly createSpaceBooking: SpaceBookingOutput,
  readonly deleteSpaceBooking: SpaceBookingOutput,
  readonly checkout: CheckoutResponse,
  readonly bulkCreateBooking: BulkCreateBookingOutput,
  readonly createBookingRequest: BookingRequest,
  readonly updateBookingRequest: BookingRequest,
  readonly updateProject?: Maybe<ProjectUpdateResponse>,
  readonly purgeProject?: Maybe<ProjectPurgeResponse>,
  readonly updateVendor: Vendor,
  readonly deleteVendor: Vendor,
  readonly updateBooking: Booking,
  readonly createBooking: Booking,
  readonly deleteBooking: Booking,
  readonly loginBooking?: Maybe<BookingLoginResponse>,
  readonly resetBookingCodes: Booking,
  readonly updateBookingStatus?: Maybe<Booking>,
  readonly updateBookingDeclineReason?: Maybe<Booking>,
  readonly declineBooking?: Maybe<Booking>,
  readonly resetBookingInvitationCode: Booking,
  readonly completeOrder?: Maybe<UpdateResponse>,
  readonly refundOrder?: Maybe<UpdateResponse>,
  readonly updateCampaign?: Maybe<Campaign>,
  readonly createCampaign?: Maybe<Campaign>,
  readonly deleteCampaign?: Maybe<Campaign>,
  readonly registerCustomer?: Maybe<Customer>,
  readonly cancelCustomerBooking?: Maybe<Response>,
};


export type MutationUpdateSpaceBookingArgs = {
  input: SpaceBookingUpdateInput
};


export type MutationCreateSpaceBookingArgs = {
  input: SpaceBookingCreateInput
};


export type MutationDeleteSpaceBookingArgs = {
  input: SpaceBookingDeleteInput
};


export type MutationCheckoutArgs = {
  projectId: Scalars['ID'],
  bookingId: Scalars['ID'],
  lineItems?: Maybe<ReadonlyArray<CheckoutLineItem>>,
  donation?: Maybe<Scalars['Float']>,
  openPriceInCents?: Maybe<Scalars['Int']>
};


export type MutationBulkCreateBookingArgs = {
  input: BulkCreateBookingInput
};


export type MutationCreateBookingRequestArgs = {
  input: CreateBookingRequestInput
};


export type MutationUpdateBookingRequestArgs = {
  input: UpdateBookingRequestInput
};


export type MutationUpdateProjectArgs = {
  projectId: Scalars['ID'],
  projectSecretKey?: Maybe<Scalars['String']>,
  config: ProjectInput
};


export type MutationPurgeProjectArgs = {
  projectId: Scalars['ID'],
  projectSecretKey: Scalars['String']
};


export type MutationUpdateVendorArgs = {
  projectId: Scalars['ID'],
  vendorId?: Maybe<Scalars['ID']>,
  projectSecretKey?: Maybe<Scalars['String']>,
  bookingId?: Maybe<Scalars['ID']>,
  invitationCode?: Maybe<Scalars['String']>,
  config: VendorInput,
  stripeDetails?: Maybe<Scalars['JSONObject']>
};


export type MutationDeleteVendorArgs = {
  input: DeleteVendorInput
};


export type MutationUpdateBookingArgs = {
  projectId: Scalars['ID'],
  bookingId?: Maybe<Scalars['ID']>,
  projectSecretKey?: Maybe<Scalars['String']>,
  config: BookingUpdateInput
};


export type MutationCreateBookingArgs = {
  projectId: Scalars['ID'],
  projectSecretKey?: Maybe<Scalars['String']>,
  config: BookingCreateInput
};


export type MutationDeleteBookingArgs = {
  projectId: Scalars['ID'],
  bookingId: Scalars['ID'],
  projectSecretKey?: Maybe<Scalars['String']>
};


export type MutationLoginBookingArgs = {
  projectId: Scalars['ID'],
  storeCode: Scalars['ID'],
  code: Scalars['String']
};


export type MutationResetBookingCodesArgs = {
  projectId: Scalars['ID'],
  bookingId: Scalars['ID'],
  projectSecretKey?: Maybe<Scalars['String']>
};


export type MutationUpdateBookingStatusArgs = {
  input: UpdateBookingStatusInput
};


export type MutationUpdateBookingDeclineReasonArgs = {
  input: UpdateBookingDeclineReasonInput
};


export type MutationDeclineBookingArgs = {
  projectId: Scalars['ID'],
  bookingId: Scalars['ID']
};


export type MutationResetBookingInvitationCodeArgs = {
  projectId: Scalars['ID'],
  bookingId: Scalars['ID'],
  projectSecretKey?: Maybe<Scalars['String']>
};


export type MutationCompleteOrderArgs = {
  projectId: Scalars['ID'],
  orderNumber: Scalars['ID'],
  bookingId: Scalars['ID']
};


export type MutationRefundOrderArgs = {
  orderNumber: Scalars['ID'],
  refundPin: Scalars['String']
};


export type MutationUpdateCampaignArgs = {
  input: CampaignUpdateInput
};


export type MutationCreateCampaignArgs = {
  input: CampaignCreateInput
};


export type MutationDeleteCampaignArgs = {
  input: CampaignDeleteInput
};


export type MutationRegisterCustomerArgs = {
  input: RegisterCustomerInput
};


export type MutationCancelCustomerBookingArgs = {
  input?: Maybe<CancelCustomerBookingInput>
};

export type SpaceBookingUpdateInput = {
  readonly storeCode?: Maybe<Scalars['ID']>,
  readonly start?: Maybe<Scalars['DateTime']>,
  readonly end?: Maybe<Scalars['DateTime']>,
  readonly description?: Maybe<Scalars['String']>,
  readonly capacity?: Maybe<Scalars['Float']>,
  readonly deleteReason?: Maybe<Scalars['String']>,
  readonly bookingId: Scalars['ID'],
};

export type SpaceBookingCreateInput = {
  readonly storeCode: Scalars['ID'],
  readonly start: Scalars['DateTime'],
  readonly end: Scalars['DateTime'],
  readonly description?: Maybe<Scalars['String']>,
  readonly capacity?: Maybe<Scalars['Float']>,
  readonly deleteReason?: Maybe<Scalars['String']>,
  readonly campaignId: Scalars['ID'],
};

export type SpaceBookingDeleteInput = {
  readonly bookingId: Scalars['ID'],
  readonly deleteReason: Scalars['String'],
};

export type CheckoutResponse = {
  readonly __typename?: 'CheckoutResponse',
  readonly sessionId: Scalars['ID'],
  readonly url: Scalars['String'],
};

export type CheckoutLineItem = {
  readonly price: Scalars['ID'],
  readonly quantity: Scalars['Float'],
  readonly productName?: Maybe<Scalars['String']>,
};

export type BulkCreateBookingOutput = {
  readonly __typename?: 'BulkCreateBookingOutput',
  readonly projectId: Scalars['ID'],
  readonly result: ReadonlyArray<BulkCreateBookingResultOutput>,
};

export type BulkCreateBookingResultOutput = {
  readonly __typename?: 'BulkCreateBookingResultOutput',
  readonly storeCode: Scalars['String'],
  readonly error?: Maybe<Scalars['String']>,
  readonly booking?: Maybe<BulkCreateBookingBookingOutput>,
};

export type BulkCreateBookingBookingOutput = {
  readonly __typename?: 'BulkCreateBookingBookingOutput',
  readonly bookingId: Scalars['String'],
  readonly status: Scalars['String'],
  readonly isRedRun: Scalars['Boolean'],
  readonly donationsEnabled: Scalars['Boolean'],
  readonly start: Scalars['String'],
  readonly end: Scalars['String'],
};

export type BulkCreateBookingInput = {
  readonly projectId: Scalars['ID'],
  readonly projectSecretKey?: Maybe<Scalars['String']>,
  readonly vendorId: Scalars['String'],
  readonly storeCodes: ReadonlyArray<Scalars['String']>,
  readonly autoConfirm?: Maybe<Scalars['Boolean']>,
  readonly date: Scalars['String'],
  readonly productIds: ReadonlyArray<Scalars['String']>,
  readonly isRedRun?: Maybe<Scalars['Boolean']>,
  readonly eventType?: Maybe<Scalars['String']>,
  readonly dryRun?: Maybe<Scalars['Boolean']>,
  readonly donationsEnabled?: Maybe<Scalars['Boolean']>,
};

export type CreateBookingRequestInput = {
  readonly projectId: Scalars['ID'],
  readonly vendorName: Scalars['String'],
  readonly vendorEmail: Scalars['String'],
  readonly vendorAltName?: Maybe<Scalars['String']>,
  readonly contactName: Scalars['String'],
  readonly contactNumber: Scalars['String'],
  readonly datePreferences: ReadonlyArray<Scalars['DateTime']>,
  readonly comments?: Maybe<Scalars['String']>,
  readonly storeCode: Scalars['String'],
};

export type UpdateBookingRequestInput = {
  readonly bookingRequestId: Scalars['ID'],
  readonly status: BookingRequestStatus,
};

export type Subscription = {
  readonly __typename?: 'Subscription',
  readonly bookingRequest: BookingRequest,
  readonly orderUpdated?: Maybe<OrderUpdated>,
  readonly orderUpdatedForBooking?: Maybe<OrderUpdated>,
};


export type SubscriptionBookingRequestArgs = {
  input: BookingRequestSubscriptionInput
};


export type SubscriptionOrderUpdatedArgs = {
  projectId: Scalars['ID'],
  bookingId: Scalars['ID'],
  orderNumber: Scalars['ID']
};

export type BookingRequestSubscriptionInput = {
  readonly storeCode?: Maybe<Scalars['String']>,
};

export type UpdateResponse = {
  readonly __typename?: 'UpdateResponse',
  readonly success: Scalars['Boolean'],
  readonly message?: Maybe<Scalars['String']>,
};

export type Response = {
  readonly __typename?: 'Response',
  readonly success: Scalars['Boolean'],
  readonly message?: Maybe<Scalars['String']>,
};

export type PageInfo = {
  readonly __typename?: 'PageInfo',
  readonly offset: Scalars['Int'],
  readonly limit: Scalars['Int'],
  readonly hasNextPage: Scalars['Boolean'],
  readonly numberOfPages: Scalars['Int'],
};

export enum CurrencyCode {
  AUD = 'AUD',
  NZD = 'NZD'
}

export type ProjectDefaultLocalzPayEventsInput = {
  readonly productIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly donationsEnabled?: Maybe<Scalars['Boolean']>,
  readonly event?: Maybe<Scalars['String']>,
};

export type ProjectDefaultLocalzPayInput = {
  readonly eventDefaults?: Maybe<ReadonlyArray<Maybe<ProjectDefaultLocalzPayEventsInput>>>,
  readonly defaultEventIndex?: Maybe<Scalars['Int']>,
};

export type ProjectDefaultsInput = {
  readonly localzPay?: Maybe<ProjectDefaultLocalzPayInput>,
};

export type Project = {
  readonly __typename?: 'Project',
  readonly projectId?: Maybe<Scalars['ID']>,
  readonly subProjectId?: Maybe<Scalars['String']>,
  readonly stripePublishableKey?: Maybe<Scalars['String']>,
  readonly stripeConnectClientId?: Maybe<Scalars['String']>,
  readonly payPortalUri?: Maybe<Scalars['String']>,
  readonly currency?: Maybe<CurrencyCode>,
  readonly eventTypes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly themeTypes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly registrationPortalUri?: Maybe<Scalars['String']>,
  readonly checkoutAmountTotalLimit?: Maybe<Scalars['Int']>,
  readonly enableBookingSummary?: Maybe<Scalars['Boolean']>,
  readonly enableBookingRequests?: Maybe<Scalars['Boolean']>,
  readonly lastUpdated?: Maybe<Scalars['DateTime']>,
  readonly defaults?: Maybe<ProjectDefaults>,
  readonly disableLocalzPayBookingMutations?: Maybe<Scalars['Boolean']>,
  readonly products: StripeProductsResponse,
};

export type ProjectInput = {
  readonly subProjectId?: Maybe<Scalars['String']>,
  readonly stripeCustomerKey?: Maybe<Scalars['String']>,
  readonly stripePlatformKey?: Maybe<Scalars['String']>,
  readonly stripePublishableKey?: Maybe<Scalars['String']>,
  readonly currency?: Maybe<CurrencyCode>,
  readonly stripeCheckoutSessionCompletedWebhookSecret?: Maybe<Scalars['String']>,
  readonly stripeAccountUpdatedWebhookSecret?: Maybe<Scalars['String']>,
  readonly stripeConnectClientId?: Maybe<Scalars['String']>,
  readonly cncWebKey?: Maybe<Scalars['String']>,
  readonly payPortalUri?: Maybe<Scalars['String']>,
  readonly registrationPortalUri?: Maybe<Scalars['String']>,
  readonly checkoutAmountTotalLimit?: Maybe<Scalars['Int']>,
  readonly enableBookingSummary?: Maybe<Scalars['Boolean']>,
  readonly enableBookingRequests?: Maybe<Scalars['Boolean']>,
  readonly eventTypes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly themeTypes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly defaults?: Maybe<ProjectDefaultsInput>,
  readonly disableLocalzPayBookingMutations?: Maybe<Scalars['Boolean']>,
};

export type ProjectDefaults = {
  readonly __typename?: 'ProjectDefaults',
  readonly localzPay?: Maybe<LocalzPayDefaults>,
};

export type LocalzPayDefaults = {
  readonly __typename?: 'LocalzPayDefaults',
  readonly defaultEventIndex?: Maybe<Scalars['Int']>,
  readonly eventDefaults?: Maybe<ReadonlyArray<Maybe<DefaultLocalzPayEvents>>>,
};

export type DefaultLocalzPayEvents = {
  readonly __typename?: 'DefaultLocalzPayEvents',
  readonly products?: Maybe<ReadonlyArray<Maybe<Scalars['JSONObject']>>>,
  readonly donationsEnabled?: Maybe<Scalars['Boolean']>,
  readonly event?: Maybe<Scalars['String']>,
};

export type ProjectUpdateResponse = {
  readonly __typename?: 'ProjectUpdateResponse',
  readonly success: Scalars['Boolean'],
  readonly message?: Maybe<Scalars['String']>,
  readonly projectId?: Maybe<Scalars['ID']>,
  readonly subProjectId?: Maybe<Scalars['String']>,
  readonly projectSecretKey?: Maybe<Scalars['String']>,
};

export type ProjectPurgeResponse = {
  readonly __typename?: 'ProjectPurgeResponse',
  readonly success: Scalars['Boolean'],
  readonly message?: Maybe<Scalars['String']>,
  readonly projectId?: Maybe<Scalars['ID']>,
};

export type StripeProduct = {
  readonly __typename?: 'StripeProduct',
  readonly active?: Maybe<Scalars['Boolean']>,
  readonly created?: Maybe<Scalars['DateTime']>,
  readonly description?: Maybe<Scalars['String']>,
  readonly id: Scalars['ID'],
  readonly livemode?: Maybe<Scalars['Boolean']>,
  readonly name?: Maybe<Scalars['String']>,
  readonly package_dimensions?: Maybe<Scalars['String']>,
  readonly shippable?: Maybe<Scalars['String']>,
  readonly statement_descriptor?: Maybe<Scalars['String']>,
  readonly tax_code?: Maybe<Scalars['String']>,
  readonly type?: Maybe<Scalars['String']>,
  readonly unit_label?: Maybe<Scalars['String']>,
  readonly updated?: Maybe<Scalars['DateTime']>,
  readonly url?: Maybe<Scalars['String']>,
};

export type StripePrice = {
  readonly __typename?: 'StripePrice',
  readonly id?: Maybe<Scalars['ID']>,
  readonly object?: Maybe<Scalars['String']>,
  readonly active?: Maybe<Scalars['Boolean']>,
  readonly billing_scheme?: Maybe<Scalars['String']>,
  readonly created?: Maybe<Scalars['DateTime']>,
  readonly currency?: Maybe<Scalars['String']>,
  readonly product?: Maybe<Scalars['ID']>,
  readonly type?: Maybe<Scalars['String']>,
  readonly unit_amount?: Maybe<Scalars['Int']>,
  readonly unit_amount_decimal?: Maybe<Scalars['String']>,
};

export type StripeProductsResponse = {
  readonly __typename?: 'StripeProductsResponse',
  readonly data?: Maybe<ReadonlyArray<Maybe<StripeProduct>>>,
  readonly has_more?: Maybe<Scalars['Boolean']>,
};

export type StripePricesResponse = {
  readonly __typename?: 'StripePricesResponse',
  readonly data?: Maybe<ReadonlyArray<Maybe<StripePrice>>>,
  readonly has_more?: Maybe<Scalars['Boolean']>,
};

export type TaxIdInput = {
  readonly type?: Maybe<TaxIdType>,
  readonly value?: Maybe<Scalars['String']>,
};

export type VendorInput = {
  readonly subProjectId?: Maybe<Scalars['String']>,
  readonly stripeUserId?: Maybe<Scalars['ID']>,
  readonly stripeAccessToken?: Maybe<Scalars['String']>,
  readonly productIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly name?: Maybe<Scalars['String']>,
  readonly altName?: Maybe<Scalars['String']>,
  readonly email?: Maybe<Scalars['String']>,
  readonly registeredStoreCode?: Maybe<Scalars['String']>,
  readonly verificationStatus?: Maybe<VendorVerificationStatus>,
  readonly lastUpdated?: Maybe<Scalars['DateTime']>,
  readonly contactName?: Maybe<Scalars['String']>,
  readonly taxId?: Maybe<TaxIdInput>,
  readonly isCharity?: Maybe<Scalars['Boolean']>,
};

export type Balance = {
  readonly __typename?: 'Balance',
  readonly available?: Maybe<ReadonlyArray<Maybe<BalanceItem>>>,
  readonly connectReserved?: Maybe<ReadonlyArray<Maybe<BalanceItem>>>,
  readonly pending?: Maybe<ReadonlyArray<Maybe<BalanceItem>>>,
};

export type BalanceItem = {
  readonly __typename?: 'BalanceItem',
  readonly amount?: Maybe<Scalars['Int']>,
  readonly currency?: Maybe<Scalars['String']>,
};

export type FindVendorInput = {
  readonly email?: Maybe<Scalars['String']>,
  readonly vendorId?: Maybe<Scalars['String']>,
};

export type DeleteVendorInput = {
  readonly vendorId: Scalars['ID'],
  readonly projectId?: Maybe<Scalars['ID']>,
  readonly projectSecretKey?: Maybe<Scalars['String']>,
};

export type SiteAddress = {
  readonly __typename?: 'SiteAddress',
  readonly country?: Maybe<Scalars['String']>,
  readonly locality?: Maybe<Scalars['String']>,
  readonly region?: Maybe<Scalars['String']>,
  readonly streetAddress?: Maybe<Scalars['String']>,
  readonly subStreetAddress?: Maybe<Scalars['String']>,
  readonly postcode?: Maybe<Scalars['String']>,
};

export type Site = {
  readonly __typename?: 'Site',
  readonly siteId: Scalars['ID'],
  readonly storeCode?: Maybe<Scalars['ID']>,
  readonly name: Scalars['String'],
  readonly phone?: Maybe<Scalars['String']>,
  readonly timezone?: Maybe<Scalars['String']>,
  readonly address?: Maybe<SiteAddress>,
  readonly attributes?: Maybe<Scalars['JSONObject']>,
  readonly campaigns?: Maybe<ReadonlyArray<Maybe<Campaign>>>,
};


export type SiteCampaignsArgs = {
  filters?: Maybe<CampaignFilterInput>
};

export type SpaceBooking = {
  readonly __typename?: 'SpaceBooking',
  readonly bookingId: Scalars['ID'],
  readonly projectId: Scalars['ID'],
  readonly campaignId?: Maybe<Scalars['ID']>,
  readonly storeCode?: Maybe<Scalars['ID']>,
  readonly start?: Maybe<Scalars['DateTime']>,
  readonly end?: Maybe<Scalars['DateTime']>,
  readonly lastUpdatedBy?: Maybe<Scalars['String']>,
  readonly createdAt?: Maybe<Scalars['DateTime']>,
  readonly updatedAt?: Maybe<Scalars['DateTime']>,
  readonly eventType?: Maybe<Scalars['String']>,
  readonly themeType?: Maybe<Scalars['String']>,
  readonly capacity?: Maybe<Scalars['Int']>,
  readonly customers?: Maybe<ReadonlyArray<Maybe<Customer>>>,
  readonly confirmedCustomerSlices?: Maybe<ReadonlyArray<Maybe<CustomerSlice>>>,
  readonly waitlistedCustomerSlices?: Maybe<ReadonlyArray<Maybe<CustomerSlice>>>,
  readonly deleteReason?: Maybe<Scalars['String']>,
  readonly registeredCount?: Maybe<Scalars['Int']>,
  readonly title?: Maybe<Scalars['String']>,
  readonly description?: Maybe<Scalars['String']>,
  readonly featureImageUri?: Maybe<Scalars['String']>,
  readonly templateType?: Maybe<TemplateType>,
  readonly donationsEnabled?: Maybe<Scalars['Boolean']>,
};

export type SpaceBookingFilterInput = {
  readonly campaignId?: Maybe<Scalars['ID']>,
  readonly storeCode?: Maybe<Scalars['ID']>,
  readonly lastUpdatedBy?: Maybe<Scalars['String']>,
  readonly eventType?: Maybe<Scalars['String']>,
  readonly themeType?: Maybe<Scalars['String']>,
};

export type BookingCreateInput = {
  readonly storeCode: Scalars['ID'],
  readonly vendorId?: Maybe<Scalars['ID']>,
  readonly start: Scalars['DateTime'],
  readonly end: Scalars['DateTime'],
  readonly productIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly name?: Maybe<Scalars['String']>,
  readonly vendorName?: Maybe<Scalars['String']>,
  readonly vendorAlt?: Maybe<Scalars['String']>,
  readonly vendorEmail?: Maybe<Scalars['String']>,
  readonly eventType: Scalars['String'],
  readonly donationsEnabled?: Maybe<Scalars['Boolean']>,
  readonly isRedRun?: Maybe<Scalars['Boolean']>,
  readonly taxId?: Maybe<TaxIdInput>,
};

export type BookingUpdateInput = {
  readonly storeCode?: Maybe<Scalars['ID']>,
  readonly vendorId?: Maybe<Scalars['ID']>,
  readonly start?: Maybe<Scalars['DateTime']>,
  readonly end?: Maybe<Scalars['DateTime']>,
  readonly productIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly name?: Maybe<Scalars['String']>,
  readonly vendorName?: Maybe<Scalars['String']>,
  readonly vendorAlt?: Maybe<Scalars['String']>,
  readonly vendorEmail?: Maybe<Scalars['String']>,
  readonly eventType?: Maybe<Scalars['String']>,
  readonly donationsEnabled?: Maybe<Scalars['Boolean']>,
};

export type DateFilter = {
  readonly after?: Maybe<Scalars['DateTime']>,
  readonly before?: Maybe<Scalars['DateTime']>,
};

export type BookingFilterInput = {
  readonly storeCode?: Maybe<Scalars['ID']>,
  readonly start?: Maybe<DateFilter>,
  readonly end?: Maybe<DateFilter>,
  readonly name?: Maybe<Scalars['String']>,
  readonly vendorName?: Maybe<Scalars['String']>,
  readonly vendorAlt?: Maybe<Scalars['String']>,
  readonly eventType?: Maybe<Scalars['String']>,
  readonly status?: Maybe<BookingStatus>,
  readonly donationsEnabled?: Maybe<Scalars['Boolean']>,
};

export type UpdateBookingStatusInput = {
  readonly projectId: Scalars['ID'],
  readonly bookingId: Scalars['ID'],
  readonly status: BookingStatus,
  readonly lastUpdatedBy: Scalars['String'],
  readonly invitationCode: Scalars['String'],
};

export type BookingDeclineReasonInput = {
  readonly reason: Scalars['String'],
  readonly additionalInfo: Scalars['String'],
};

export type UpdateBookingDeclineReasonInput = {
  readonly projectId: Scalars['ID'],
  readonly bookingId: Scalars['ID'],
  readonly lastUpdatedBy: Scalars['String'],
  readonly invitationCode: Scalars['String'],
  readonly declineReason?: Maybe<BookingDeclineReasonInput>,
};

export type Invitation = {
  readonly __typename?: 'Invitation',
  readonly bookingId?: Maybe<Scalars['ID']>,
  readonly projectId?: Maybe<Scalars['ID']>,
  readonly vendorId?: Maybe<Scalars['ID']>,
  readonly usePayment?: Maybe<Scalars['Boolean']>,
  readonly vendor?: Maybe<Vendor>,
  readonly booking?: Maybe<Booking>,
};

export type FindUpcomingBookingsInput = {
  readonly startDate: Scalars['DateTime'],
  readonly endDate: Scalars['DateTime'],
};

export type BookingsByStoreInput = {
  readonly storeCode: Scalars['String'],
  readonly search?: Maybe<Scalars['String']>,
  readonly status?: Maybe<BookingStatus>,
};

export type FindBookingInput = {
  readonly bookingId?: Maybe<Scalars['ID']>,
};

export type BookingLoginResponse = {
  readonly __typename?: 'BookingLoginResponse',
  readonly token?: Maybe<Scalars['String']>,
};

export enum OrderStatus {
  READY = 'READY',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED'
}

export type Order = {
  readonly __typename?: 'Order',
  readonly orderNumber?: Maybe<Scalars['ID']>,
  readonly orderDate?: Maybe<Scalars['DateTime']>,
  readonly orderStatus?: Maybe<OrderStatus>,
  readonly orderAmount?: Maybe<Scalars['Int']>,
  readonly checkoutSessionId?: Maybe<Scalars['ID']>,
  readonly stripeReceiptUrl?: Maybe<Scalars['String']>,
  readonly successUrl?: Maybe<Scalars['String']>,
  readonly vendorId?: Maybe<Scalars['ID']>,
  readonly projectId?: Maybe<Scalars['ID']>,
  readonly bookingId?: Maybe<Scalars['ID']>,
  readonly paymentIntentId?: Maybe<Scalars['ID']>,
  readonly refundId?: Maybe<Scalars['ID']>,
  readonly refundAmount?: Maybe<Scalars['Int']>,
  readonly refundStatus?: Maybe<Scalars['String']>,
  readonly items?: Maybe<ReadonlyArray<Maybe<OrderItem>>>,
  readonly donation?: Maybe<Scalars['Float']>,
};

export type OrderItem = {
  readonly __typename?: 'OrderItem',
  readonly itemId?: Maybe<Scalars['ID']>,
  readonly totalAmount?: Maybe<Scalars['Int']>,
  readonly unitAmount?: Maybe<Scalars['Int']>,
  readonly quantity?: Maybe<Scalars['Int']>,
  readonly name?: Maybe<Scalars['String']>,
  readonly currency?: Maybe<Scalars['String']>,
  readonly productId?: Maybe<Scalars['String']>,
};

export enum OrderUpdatedTopic {
  CREATED = 'Created',
  COMPLETED = 'Completed',
  REFUNDED = 'Refunded'
}

export type OrderUpdated = {
  readonly __typename?: 'OrderUpdated',
  readonly projectId: Scalars['ID'],
  readonly orderNumber: Scalars['ID'],
  readonly bookingId: Scalars['ID'],
  readonly topic?: Maybe<OrderUpdatedTopic>,
};

export type DateRange = {
  readonly __typename?: 'DateRange',
  readonly start?: Maybe<Scalars['String']>,
  readonly end?: Maybe<Scalars['String']>,
};

export type DateRangeInput = {
  readonly start: Scalars['String'],
  readonly end: Scalars['String'],
};

export type Campaign = {
  readonly __typename?: 'Campaign',
  readonly campaignId?: Maybe<Scalars['ID']>,
  readonly projectId?: Maybe<Scalars['ID']>,
  readonly bookings?: Maybe<ReadonlyArray<Maybe<SpaceBooking>>>,
  readonly lastUpdated?: Maybe<Scalars['DateTime']>,
  readonly dateRanges?: Maybe<ReadonlyArray<Maybe<DateRange>>>,
  readonly storeCodes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly eventType?: Maybe<Scalars['String']>,
  readonly themeType?: Maybe<Scalars['String']>,
  readonly title?: Maybe<Scalars['String']>,
  readonly description?: Maybe<Scalars['String']>,
  readonly featureImageUri?: Maybe<Scalars['String']>,
  readonly locationLabels?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly deleteReason?: Maybe<Scalars['String']>,
  readonly templateType?: Maybe<TemplateType>,
  readonly isHidden?: Maybe<Scalars['Boolean']>,
};


export type CampaignBookingsArgs = {
  filters?: Maybe<SpaceBookingFilterInput>
};

export type FindCampaignsResponse = {
  readonly __typename?: 'FindCampaignsResponse',
  readonly campaigns?: Maybe<ReadonlyArray<Maybe<Campaign>>>,
  readonly pageInfo?: Maybe<PageInfo>,
};

export type CampaignCreateInput = {
  readonly dateRanges?: Maybe<ReadonlyArray<Maybe<DateRangeInput>>>,
  readonly storeCodes: ReadonlyArray<Scalars['String']>,
  readonly eventType: Scalars['String'],
  readonly themeType: Scalars['String'],
  readonly title?: Maybe<Scalars['String']>,
  readonly description?: Maybe<Scalars['String']>,
  readonly featureImageUri: Scalars['String'],
  readonly locationLabels: ReadonlyArray<Maybe<Scalars['String']>>,
  readonly templateType: TemplateType,
  readonly isHidden?: Maybe<Scalars['Boolean']>,
};

export type CampaignUpdateInput = {
  readonly campaignId: Scalars['ID'],
  readonly title?: Maybe<Scalars['String']>,
  readonly description?: Maybe<Scalars['String']>,
  readonly featureImageUri?: Maybe<Scalars['String']>,
  readonly locationLabels?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly dateRanges?: Maybe<ReadonlyArray<Maybe<DateRangeInput>>>,
  readonly storeCodes?: Maybe<ReadonlyArray<Scalars['String']>>,
  readonly isHidden?: Maybe<Scalars['Boolean']>,
};

export type CampaignDeleteInput = {
  readonly campaignId: Scalars['ID'],
  readonly deleteReason: Scalars['String'],
};

export type CampaignGetInput = {
  readonly showHidden?: Maybe<Scalars['Boolean']>,
  readonly lastUpdated?: Maybe<Scalars['DateTime']>,
  readonly storeCodes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly eventType?: Maybe<Scalars['String']>,
  readonly themeType?: Maybe<Scalars['String']>,
  readonly campaignId?: Maybe<Scalars['ID']>,
  readonly title?: Maybe<Scalars['String']>,
  readonly description?: Maybe<Scalars['String']>,
  readonly locationLabels?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly templateType?: Maybe<TemplateType>,
};

export type CampaignFilterInput = {
  readonly locationLabels?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>,
  readonly eventType?: Maybe<Scalars['String']>,
  readonly themeType?: Maybe<Scalars['String']>,
  readonly templateType?: Maybe<TemplateType>,
  readonly start?: Maybe<Scalars['String']>,
  readonly end?: Maybe<Scalars['String']>,
};

export enum Status {
  CONFIRMED = 'CONFIRMED',
  WAITING_LIST = 'WAITING_LIST'
}

export type RegisterCustomerInput = {
  readonly name: Scalars['String'],
  readonly email: Scalars['String'],
  readonly tel?: Maybe<Scalars['String']>,
  readonly addAdults: Scalars['Int'],
  readonly addChildren: Scalars['Int'],
  readonly bookingId: Scalars['String'],
  readonly optIn?: Maybe<Scalars['Boolean']>,
};

export type Customer = {
  readonly __typename?: 'Customer',
  readonly customerId: Scalars['String'],
  readonly status?: Maybe<Status>,
  readonly email?: Maybe<Scalars['String']>,
  readonly optIn?: Maybe<Scalars['Boolean']>,
  readonly name?: Maybe<Scalars['String']>,
  readonly tel?: Maybe<Scalars['String']>,
  readonly projectId?: Maybe<Scalars['ID']>,
};

export type CustomerSlice = {
  readonly __typename?: 'CustomerSlice',
  readonly customerId: Scalars['String'],
  readonly addAdults: Scalars['Int'],
  readonly addChildren: Scalars['Int'],
};

export type CancelCustomerBookingInput = {
  readonly bookingId: Scalars['ID'],
  readonly customerId: Scalars['ID'],
};

export type RegisterCustomerMutationVariables = {
  input: RegisterCustomerInput
};


export type RegisterCustomerMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly registerCustomer: Maybe<(
    { readonly __typename?: 'Customer' }
    & Pick<Customer, 'customerId' | 'status'>
  )> }
);

export type CancelCustomerBookingMutationVariables = {
  input: CancelCustomerBookingInput
};


export type CancelCustomerBookingMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly cancelCustomerBooking: Maybe<(
    { readonly __typename?: 'Response' }
    & Pick<Response, 'success' | 'message'>
  )> }
);

export type CreateBookingRequestMutationVariables = {
  input: CreateBookingRequestInput
};


export type CreateBookingRequestMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createBookingRequest: (
    { readonly __typename?: 'BookingRequest' }
    & Pick<BookingRequest, 'bookingRequestId' | 'projectId' | 'storeCode' | 'vendorId' | 'vendorName' | 'vendorAltName' | 'datePreferences' | 'comments' | 'contactName' | 'contactNumber' | 'status' | 'triggeredVendorCreation'>
  ) }
);

export type SpaceBookingsQueryVariables = {
  projectId: Scalars['ID'],
  filters?: Maybe<SpaceBookingInput>
};


export type SpaceBookingsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly findBookingsUsingEndDate: ReadonlyArray<(
    { readonly __typename?: 'SpaceBookingOutput' }
    & Pick<SpaceBookingOutput, 'bookingId' | 'campaignId' | 'start' | 'end' | 'featureImageUri' | 'eventType' | 'storeCode' | 'title' | 'description' | 'capacity' | 'themeType' | 'registeredCount'>
  )> }
);

export type SpaceBookingQueryVariables = {
  input: SpaceBookingGetInput
};


export type SpaceBookingQuery = (
  { readonly __typename?: 'Query' }
  & { readonly findSpaceBooking: (
    { readonly __typename?: 'SpaceBookingOutput' }
    & Pick<SpaceBookingOutput, 'bookingId' | 'capacity' | 'featureImageUri' | 'registeredCount' | 'eventType' | 'themeType' | 'storeCode' | 'title' | 'description' | 'start' | 'end'>
  ) }
);

export type SitesQueryVariables = {
  projectId: Scalars['ID']
};


export type SitesQuery = (
  { readonly __typename?: 'Query' }
  & { readonly sites: Maybe<ReadonlyArray<(
    { readonly __typename?: 'Site' }
    & Pick<Site, 'siteId' | 'name' | 'storeCode' | 'attributes' | 'timezone'>
    & { readonly address: Maybe<(
      { readonly __typename?: 'SiteAddress' }
      & Pick<SiteAddress, 'country' | 'locality' | 'region' | 'streetAddress' | 'subStreetAddress' | 'postcode'>
    )> }
  )>> }
);

export type GetProjectQueryVariables = {
  projectId: Scalars['ID'],
  subProjectId?: Maybe<Scalars['String']>
};


export type GetProjectQuery = (
  { readonly __typename?: 'Query' }
  & { readonly findProject: Maybe<(
    { readonly __typename?: 'Project' }
    & Pick<Project, 'enableBookingRequests'>
  )> }
);


export const RegisterCustomerDocument = gql`
    mutation registerCustomer($input: RegisterCustomerInput!) {
  registerCustomer(input: $input) {
    customerId
    status
  }
}
    `;
export type RegisterCustomerMutationFn = ApolloReactCommon.MutationFunction<RegisterCustomerMutation, RegisterCustomerMutationVariables>;

/**
 * __useRegisterCustomerMutation__
 *
 * To run a mutation, you first call `useRegisterCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCustomerMutation, { data, loading, error }] = useRegisterCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterCustomerMutation, RegisterCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterCustomerMutation, RegisterCustomerMutationVariables>(RegisterCustomerDocument, baseOptions);
      }
export type RegisterCustomerMutationHookResult = ReturnType<typeof useRegisterCustomerMutation>;
export type RegisterCustomerMutationResult = ApolloReactCommon.MutationResult<RegisterCustomerMutation>;
export type RegisterCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterCustomerMutation, RegisterCustomerMutationVariables>;
export const CancelCustomerBookingDocument = gql`
    mutation cancelCustomerBooking($input: CancelCustomerBookingInput!) {
  cancelCustomerBooking(input: $input) {
    success
    message
  }
}
    `;
export type CancelCustomerBookingMutationFn = ApolloReactCommon.MutationFunction<CancelCustomerBookingMutation, CancelCustomerBookingMutationVariables>;

/**
 * __useCancelCustomerBookingMutation__
 *
 * To run a mutation, you first call `useCancelCustomerBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCustomerBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCustomerBookingMutation, { data, loading, error }] = useCancelCustomerBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCustomerBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCustomerBookingMutation, CancelCustomerBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCustomerBookingMutation, CancelCustomerBookingMutationVariables>(CancelCustomerBookingDocument, baseOptions);
      }
export type CancelCustomerBookingMutationHookResult = ReturnType<typeof useCancelCustomerBookingMutation>;
export type CancelCustomerBookingMutationResult = ApolloReactCommon.MutationResult<CancelCustomerBookingMutation>;
export type CancelCustomerBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCustomerBookingMutation, CancelCustomerBookingMutationVariables>;
export const CreateBookingRequestDocument = gql`
    mutation createBookingRequest($input: CreateBookingRequestInput!) {
  createBookingRequest(input: $input) {
    bookingRequestId
    projectId
    storeCode
    vendorId
    vendorName
    vendorAltName
    datePreferences
    comments
    contactName
    contactNumber
    status
    triggeredVendorCreation
  }
}
    `;
export type CreateBookingRequestMutationFn = ApolloReactCommon.MutationFunction<CreateBookingRequestMutation, CreateBookingRequestMutationVariables>;

/**
 * __useCreateBookingRequestMutation__
 *
 * To run a mutation, you first call `useCreateBookingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingRequestMutation, { data, loading, error }] = useCreateBookingRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookingRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBookingRequestMutation, CreateBookingRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBookingRequestMutation, CreateBookingRequestMutationVariables>(CreateBookingRequestDocument, baseOptions);
      }
export type CreateBookingRequestMutationHookResult = ReturnType<typeof useCreateBookingRequestMutation>;
export type CreateBookingRequestMutationResult = ApolloReactCommon.MutationResult<CreateBookingRequestMutation>;
export type CreateBookingRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBookingRequestMutation, CreateBookingRequestMutationVariables>;
export const SpaceBookingsDocument = gql`
    query spaceBookings($projectId: ID!, $filters: SpaceBookingInput) {
  findBookingsUsingEndDate(projectId: $projectId, filters: $filters) {
    bookingId
    campaignId
    start
    end
    featureImageUri
    eventType
    storeCode
    title
    description
    capacity
    themeType
    registeredCount
  }
}
    `;

/**
 * __useSpaceBookingsQuery__
 *
 * To run a query within a React component, call `useSpaceBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceBookingsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSpaceBookingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SpaceBookingsQuery, SpaceBookingsQueryVariables>) {
        return ApolloReactHooks.useQuery<SpaceBookingsQuery, SpaceBookingsQueryVariables>(SpaceBookingsDocument, baseOptions);
      }
export function useSpaceBookingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpaceBookingsQuery, SpaceBookingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SpaceBookingsQuery, SpaceBookingsQueryVariables>(SpaceBookingsDocument, baseOptions);
        }
export type SpaceBookingsQueryHookResult = ReturnType<typeof useSpaceBookingsQuery>;
export type SpaceBookingsLazyQueryHookResult = ReturnType<typeof useSpaceBookingsLazyQuery>;
export type SpaceBookingsQueryResult = ApolloReactCommon.QueryResult<SpaceBookingsQuery, SpaceBookingsQueryVariables>;
export const SpaceBookingDocument = gql`
    query spaceBooking($input: SpaceBookingGetInput!) {
  findSpaceBooking(input: $input) {
    bookingId
    capacity
    featureImageUri
    registeredCount
    eventType
    themeType
    storeCode
    title
    description
    start
    end
  }
}
    `;

/**
 * __useSpaceBookingQuery__
 *
 * To run a query within a React component, call `useSpaceBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceBookingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceBookingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SpaceBookingQuery, SpaceBookingQueryVariables>) {
        return ApolloReactHooks.useQuery<SpaceBookingQuery, SpaceBookingQueryVariables>(SpaceBookingDocument, baseOptions);
      }
export function useSpaceBookingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpaceBookingQuery, SpaceBookingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SpaceBookingQuery, SpaceBookingQueryVariables>(SpaceBookingDocument, baseOptions);
        }
export type SpaceBookingQueryHookResult = ReturnType<typeof useSpaceBookingQuery>;
export type SpaceBookingLazyQueryHookResult = ReturnType<typeof useSpaceBookingLazyQuery>;
export type SpaceBookingQueryResult = ApolloReactCommon.QueryResult<SpaceBookingQuery, SpaceBookingQueryVariables>;
export const SitesDocument = gql`
    query sites($projectId: ID!) {
  sites(projectId: $projectId) {
    siteId
    name
    storeCode
    attributes
    timezone
    address {
      country
      locality
      region
      streetAddress
      subStreetAddress
      postcode
    }
  }
}
    `;

/**
 * __useSitesQuery__
 *
 * To run a query within a React component, call `useSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSitesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SitesQuery, SitesQueryVariables>) {
        return ApolloReactHooks.useQuery<SitesQuery, SitesQueryVariables>(SitesDocument, baseOptions);
      }
export function useSitesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SitesQuery, SitesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SitesQuery, SitesQueryVariables>(SitesDocument, baseOptions);
        }
export type SitesQueryHookResult = ReturnType<typeof useSitesQuery>;
export type SitesLazyQueryHookResult = ReturnType<typeof useSitesLazyQuery>;
export type SitesQueryResult = ApolloReactCommon.QueryResult<SitesQuery, SitesQueryVariables>;
export const GetProjectDocument = gql`
    query getProject($projectId: ID!, $subProjectId: String) {
  findProject(projectId: $projectId, subProjectId: $subProjectId) {
    enableBookingRequests
  }
}
    `;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      subProjectId: // value for 'subProjectId'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
      }
export function useGetProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = ApolloReactCommon.QueryResult<GetProjectQuery, GetProjectQueryVariables>;