import { useMemo, useState } from 'react'
import { get, isEmpty } from 'lodash'
import {
  Button,
  Label,
  FieldStack,
  Flex,
  Text,
  TextareaField,
  Heading,
  Link as BumbagLink,
  Overlay,
} from 'bumbag'
import { useParams } from 'react-router-dom'
import { object, string } from 'yup'
import { useForm, Controller, useWatch } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useSitesState } from 'context/Sites'
import useYupValidationResolver from 'utils/useYupValidationResolver'
import { getStoreCodeFromParam } from 'utils/storeCode'
import { Site, useCreateBookingRequestMutation } from 'generated'
import RegionSelect, { StyledAsterisk } from '../RegionSelect'
import SiteSelect from '../SiteSelect'
import { MobileInput } from '../RegisterForm'
import Input, { ErrorMessage } from './Input'
import { ConfirmationModal } from './ConfirmationModal'
import FullScreenLoading from 'components/FullScreenLoading'

const PENDING_ENQUIRY_ERROR = 'A vendor can only submit one enquiry at a time'

export interface EnquiryDetails {
  contactName: string
  contactNumber: string
  vendorName: string
  vendorAltName: string
  vendorEmail: string
  comments: string
  region: string | null
  storeCode: string | null
}

const validationSchema = object({
  contactName: string()
    .required('Name is required')
    .max(
      100,
      options => `This can only be at most ${options.max} characters long.`
    ),
  contactNumber: string().required('Mobile number is required'),
  vendorName: string()
    .required('Community group name is required')
    .max(
      100,
      options => `This can only be at most ${options.max} characters long.`
    ),
  vendorAltName: string().max(
    100,
    options => `This can only be at most ${options.max} characters long.`
  ),
  vendorEmail: string()
    .required('Email is required')
    .max(
      100,
      options => `This can only be at most ${options.max} characters long.`
    ),
  comments: string().max(
    300,
    options => `This can only be at most ${options.max} characters long.`
  ),
  region: string().required('State is required'),
  storeCode: string().required('Store is required'),
})

function RegisterForm() {
  const { projectId } = useParams<{
    projectId: string
  }>()
  const urlParams = new URLSearchParams(window.location.search)
  const storeCodeParam = getStoreCodeFromParam()

  const [showModal, setShowModal] = useState<boolean>(false)

  const { sites, loading: sitesLoading, sitesByStoreCode } = useSitesState()
  const regions = useMemo(
    () =>
      sites.reduce((acc, site) => {
        if (site.address?.region) {
          acc.add(site.address?.region)
        }
        return acc
      }, new Set<string>()),
    [sites]
  )

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    reset,
    trigger,
    getValues,
  } = useForm<EnquiryDetails>({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: {
      contactName: '',
      contactNumber: '',
      vendorName: '',
      vendorAltName: '',
      vendorEmail: '',
      comments: '',
      region: urlParams.get('region') || '',
      storeCode: storeCodeParam || '',
    },
  })

  const region = useWatch({ control, name: 'region' })
  const filteredSites = useMemo(
    () =>
      region ? sites.filter(site => site?.address?.region === region) : sites,
    [region, sites]
  )

  const [
    createBookingRequest,
    { loading: isSubmitting, error, called },
  ] = useCreateBookingRequestMutation({
    onCompleted: () => {
      setShowModal(false)
      reset()
    },
    onError: () => {
      setShowModal(false)
    },
  })

  const onSubmit = () => setShowModal(true)

  const onConfirm = (data: EnquiryDetails) => {
    const {
      vendorName,
      vendorEmail,
      vendorAltName,
      contactName,
      contactNumber,
      comments,
      storeCode,
    } = data

    if (storeCode) {
      createBookingRequest({
        variables: {
          input: {
            vendorName,
            vendorEmail,
            vendorAltName,
            contactName,
            contactNumber,
            comments,
            projectId,
            datePreferences: [],
            storeCode,
          },
        },
      })
    }
  }

  return (
    <Overlay.State>
      <Flex
        marginTop="major-2"
        flexDirection="column"
        flex="1"
        use="form"
        onSubmit={handleSubmit(onSubmit)}
        paddingBottom="major-2"
      >
        {isSubmitting && <FullScreenLoading />}
        {showModal && !isEmpty(getValues()) && (
          <ConfirmationModal
            values={getValues()}
            sitesByStoreCode={sitesByStoreCode}
            onCancel={() => setShowModal(false)}
            onConfirm={(data: EnquiryDetails) => onConfirm(data)}
            isSubmitting={isSubmitting}
          />
        )}
        <Heading fontSize="500">Sausage Sizzle Enquiry</Heading>
        {called && !isSubmitting && !error && (
          <Text marginTop="major-2">
            Thank you! Your enquiry has been successfully submitted and your
            local Activities Organiser will be in touch soon.
          </Text>
        )}
        {called && !isSubmitting && error && (
          <Text marginTop="major-2">
            {error.message === PENDING_ENQUIRY_ERROR
              ? 'Sorry we hit a snag! It looks like you have already submitted this enquiry. We are unable to accept duplicate submissions. Thanks again and your local Activities Organiser will be in touch soon.'
              : 'Something went wrong, please try again.'}
          </Text>
        )}
        {!called && !error && (
          <>
            <Text marginTop="major-2">
              To register your interest in hosting a Bunnings sausage sizzle,
              please complete your details in the form below;
            </Text>
            <FieldStack
              flex="1"
              display="flex"
              flexDirection="column"
              marginTop="major-2"
            >
              <Input
                placeholder="Linda"
                label="Name"
                name="contactName"
                err={get(errors, 'contactName.message')}
                control={control}
              />
              <Input
                placeholder="linda@example.com"
                label="Email"
                name="vendorEmail"
                err={get(errors, 'vendorEmail.message')}
                control={control}
                type="email"
              />
              <Label>
                Mobile Number <StyledAsterisk>*</StyledAsterisk>
              </Label>
              <Controller
                control={control}
                name="contactNumber"
                render={({ value, onChange }) => (
                  <PhoneInput
                    placeholder="0434524346"
                    value={value}
                    autoComplete="tel-local"
                    onChange={onChange}
                    smartCaret={false}
                    inputComponent={MobileInput}
                    defaultCountry="AU"
                    // @ts-ignore
                    isRequired
                    state={
                      get(errors, 'contactNumber.message')
                        ? 'danger'
                        : undefined
                    }
                  />
                )}
              />
              <ErrorMessage err={get(errors, 'contactNumber.message')} />
              <Controller
                control={control}
                name="region"
                render={({ value, onChange }) => (
                  <RegionSelect
                    regions={[...regions]}
                    onSelect={(selectedRegion: string) => {
                      onChange(selectedRegion)
                      setValue('storeCode', '')
                    }}
                    currentRegion={value}
                    loading={sitesLoading}
                    state={get(errors, 'region.message') ? 'danger' : undefined}
                    labelProps={{
                      marginBottom: '0.75rem',
                    }}
                    required
                    disableClear
                  />
                )}
              />
              <ErrorMessage err={get(errors, 'region.message')} />
              <Controller
                control={control}
                name="storeCode"
                render={({ value, onChange }) => (
                  <SiteSelect
                    onSelect={(selectedSite: Partial<Site> | null) => {
                      onChange(selectedSite?.storeCode)
                      if (selectedSite) {
                        setValue('region', selectedSite.address?.region)
                      }
                      trigger('region')
                    }}
                    sites={filteredSites}
                    loading={sitesLoading}
                    currentSite={sitesByStoreCode[value || '']}
                    state={
                      get(errors, 'storeCode.message') ? 'danger' : undefined
                    }
                    labelProps={{
                      marginBottom: '0.75rem',
                    }}
                    required
                    disableClear
                  />
                )}
              />
              <ErrorMessage err={get(errors, 'storeCode.message')} />
              <Input
                placeholder="Community group name"
                label="Community Group Name"
                name="vendorName"
                err={get(errors, 'vendorName.message')}
                control={control}
              />
              <Input
                placeholder="Community group alternate name"
                label="Community Group Alternate Name"
                name="vendorAltName"
                err={get(errors, 'vendorAltName.message')}
                control={control}
                isRequired={false}
              />
              <Controller
                control={control}
                name="comments"
                render={({ value, onChange }) => (
                  <TextareaField
                    placeholder="Any additional comments"
                    defaultValue=""
                    label="Additional comments"
                    isRequired={false}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <ErrorMessage err={get(errors, 'comments.message')} />
              <Label fontWeight="normal" fontSize="100">
                Terms and conditions: Bunnings is collecting your personal
                information to process your sausage sizzle booking enquiry as
                well as for the other purposes set out in our{' '}
                <BumbagLink
                  href="https://www.bunnings.com.au/privacy-policy"
                  // @ts-ignore
                  target="_blank"
                >
                  Privacy Policy
                </BumbagLink>
                . Bunnings collects and manages your personal information in
                accordance with our{' '}
                <BumbagLink
                  href="https://www.bunnings.com.au/privacy-policy"
                  // @ts-ignore
                  target="_blank"
                >
                  Privacy Policy
                </BumbagLink>
                . Submitting this enquiry will simply record your interest in
                hosting a sausage sizzle. Actual event bookings are managed by
                your stores Activities Organiser, who will be in contact with
                you following submission.
              </Label>
              <Button
                type="submit"
                palette="secondary"
                alignSelf="flex-end"
                float="right"
                minWidth="12rem"
              >
                Submit
              </Button>
            </FieldStack>
          </>
        )}
      </Flex>
    </Overlay.State>
  )
}

export default RegisterForm
