import { Dispatch, forwardRef, SetStateAction, useState, useMemo } from 'react'
import { get } from 'lodash'
import {
  InputField,
  Group,
  Button,
  Label,
  FieldStack,
  Flex,
  Text,
  BoxProps,
} from 'bumbag'
import { useParams } from 'react-router-dom'
import { object, string, number, boolean } from 'yup'
import {
  useForm,
  Controller,
  useWatch,
  Control,
  DeepMap,
  FieldError,
} from 'react-hook-form'
import { SpaceBookingOutput, useRegisterCustomerMutation } from 'generated'
import useYupValidationResolver from 'utils/useYupValidationResolver'
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Privacy from './Privacy'
import { eventTypes } from 'constants/events'

interface Props {
  addAdults: number
  addChildren: number
  tel?: string
  email: string
  name: string
}
const DEFAULT_MAX_PEOPLE = 10
export const MobileInput = forwardRef(({ children, ...props }, ref) => (
  <InputField ref={ref} {...props} />
))

function NumberInput({
  control,
  path,
  label,
  min = 0,
  flexProps,
  defaultValue,
  postChange,
  max,
}: {
  errors: DeepMap<{}, FieldError>
  control: Control<Partial<Props>>
  path: string
  defaultValue: number
  label: string
  min?: number
  max: number
  flexProps?: BoxProps
  postChange?: Function
}) {
  const value = useWatch({
    control,
    name: path,
    defaultValue: defaultValue.toString(),
  })

  return (
    <Flex
      flexDirection="column"
      maxWidth="15rem"
      paddingTop="1rem"
      {...flexProps}
    >
      <Label>{label}</Label>
      <Group borderColor="#d8d8d8" borderWidth="1px" borderStyle="solid">
        <Controller
          control={control}
          name={path}
          render={({ onChange }) => (
            <Button
              variant="ghost"
              onClick={() => {
                onChange(Number(value) - 1)
                postChange?.()
              }}
              disabled={Number(value) <= min}
            >
              -
            </Button>
          )}
        />
        <Controller
          flex="1"
          control={control}
          name={path}
          isRequired
          render={({ onChange }) => (
            <InputField
              onChange={(e: any) => {
                const val = e.target.value
                if (/^[0-9]*$/.test(val) && (!val || Number(val) >= min)) {
                  onChange(val ? Number(val) : '')
                  postChange?.()
                }
              }}
              value={value}
              inputProps={{
                textAlign: 'center',
                backgroundColor: 'transparent',
                border: 'none',
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={path}
          render={({ onChange }) => (
            <Button
              variant="ghost"
              onClick={() => {
                onChange(Number(value) + 1)
                postChange?.()
              }}
              disabled={Number(value) >= max}
            >
              +
            </Button>
          )}
        />
      </Group>
      {value === '' && (
        <Text fontSize="150" color="danger" marginTop="major-1">
          Value cannot be empty
        </Text>
      )}
    </Flex>
  )
}

const validationSchema = object({
  name: string().required('Name is required'),
  email: string().required('Email is required'),
  tel: string(),
  addAdults: number()
    .test('max', function(value) {
      // @ts-ignore
      const { maxAllowedCapacity, eventType } = this.options.context

      if (eventType === eventTypes.KidsDIY) {
        return true
      }

      const { addChildren = 0 } = this.parent
      return (value || 0) <= maxAllowedCapacity - addChildren
    })
    .test('total', 'Number of people attending must be at least 1', function(
      value
    ) {
      // @ts-ignore
      const { eventType } = this.options.context

      if (eventType === eventTypes.KidsDIY) {
        return true
      }

      const { addChildren = 0 } = this.parent
      return addChildren + value > 0
    }),
  addChildren: number()
    .test('max', function(value) {
      // @ts-ignore
      const { maxAllowedCapacity, eventType } = this.options.context

      if (eventType === eventTypes.AdultDIY) {
        return true
      }

      const { addAdults = 0 } = this.parent
      return (value || 0) <= maxAllowedCapacity - addAdults
    })
    .test('total', 'Number of people attending must be at least 1', function(
      value
    ) {
      // @ts-ignore
      const { eventType } = this.options.context

      if (eventType === eventTypes.AdultDIY) {
        return true
      }

      const { addAdults = 0 } = this.parent
      return addAdults + value > 0
    }),
  optIn: boolean(),
})

function RegisterForm({
  booking,
  setHasSubmitted,
}: {
  setHasSubmitted: Dispatch<SetStateAction<boolean>>
  booking: SpaceBookingOutput
}) {
  const [emailError, setEmailError] = useState('')
  const { bookingId } = useParams<{
    projectId: string
    bookingId: string
  }>()

  const maxAllowedCapacity = useMemo(() => {
    if (booking) {
      const spotsLeft = booking.capacity! - booking.registeredCount!
      if (spotsLeft < 10) return spotsLeft
    }
    return 10
  }, [booking])

  const typicalAllowedCapacity = useMemo(
    () => (booking.capacity && booking.capacity < 10 ? booking.capacity : 10),
    [booking]
  )

  const [submit, { loading: isSubmitting }] = useRegisterCustomerMutation({
    onCompleted: ({ registerCustomer }) => {
      if (registerCustomer) {
        setHasSubmitted(true)
      }
    },
    onError: e => {
      setEmailError('This email is already registered on this booking')
    },
  })

  const { handleSubmit, errors, control, trigger } = useForm({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: {
      optIn: false,
      addAdults: 0,
      addChildren: 0,
    },
    context: { maxAllowedCapacity, eventType: booking.eventType },
  })

  const addChildren = useWatch<number>({
    control,
    name: 'addChildren',
    defaultValue: 0,
  })

  const addAdults = useWatch<number>({
    control,
    name: 'addAdults',
    defaultValue: 0,
  })

  const maxedPeopleAdded = addAdults + addChildren > maxAllowedCapacity
  const onSubmit = (data: Props) => {
    submit({
      variables: {
        input: {
          bookingId,
          ...data,
          tel: data.tel && formatPhoneNumberIntl(data.tel),
          addChildren: data.addChildren || 0,
          addAdults: data.addAdults || 0,
        },
      },
    })
  }

  return (
    <Flex
      marginTop="major-2"
      flexDirection="column"
      flex="1"
      use="form"
      onSubmit={handleSubmit(onSubmit)}
      paddingBottom="major-2"
    >
      <FieldStack flex="1" display="flex" flexDirection="column">
        <Controller
          control={control}
          name="name"
          render={({ value, onChange }) => (
            <InputField
              placeholder="Linda"
              defaultValue=""
              label="Name"
              isRequired
              value={value}
              autoComplete="name"
              onChange={onChange}
              state={get(errors, 'name.message') ? 'danger' : undefined}
            />
          )}
        />
        {get(errors, 'name.message', '') && (
          <Text fontSize="150" color="danger">
            {get(errors, 'name.message', '')}
          </Text>
        )}

        <Controller
          control={control}
          name="email"
          render={({ value, onChange }) => (
            <InputField
              state={
                get(errors, 'email.message') || emailError
                  ? 'danger'
                  : undefined
              }
              placeholder="linda@example.com"
              type="email"
              defaultValue=""
              label="Email"
              isRequired
              value={value}
              autoComplete="email"
              onChange={onChange}
            />
          )}
        />
        {(get(errors, 'email.message') || emailError) && (
          <Text fontSize="150" color="danger">
            {get(errors, 'email.message') || emailError}
          </Text>
        )}

        <Label>Mobile Number </Label>
        <Controller
          control={control}
          name="tel"
          render={({ value, onChange }) => (
            <PhoneInput
              placeholder="0434524346"
              value={value}
              autoComplete="tel-local"
              onChange={onChange}
              smartCaret={false}
              inputComponent={MobileInput}
              defaultCountry="AU"
            />
          )}
        />
        {get(errors, 'tel.message', '') && (
          <Text fontSize="150" color="danger">
            {get(errors, 'tel.message', '')}
          </Text>
        )}

        <Flex flexWrap="wrap" justifyContent="space-between">
          {booking.eventType !== eventTypes.KidsDIY && (
            <NumberInput
              errors={errors}
              path="addAdults"
              control={control}
              label="Adults"
              defaultValue={0}
              max={maxAllowedCapacity}
              // @ts-ignore
              postChange={() => trigger(['addChildren', 'addAdults'])}
            />
          )}
          {booking.eventType !== eventTypes.AdultDIY && (
            <NumberInput
              defaultValue={0}
              errors={errors}
              path="addChildren"
              control={control}
              label="Children"
              max={maxAllowedCapacity}
              // @ts-ignore
              postChange={() => trigger(['addChildren', 'addAdults'])}
            />
          )}
        </Flex>
        {maxedPeopleAdded && (
          <Text fontSize="150" color="danger">
            Total number of people cannot be above {maxAllowedCapacity}
          </Text>
        )}

        {['total'].includes(
          get(errors, 'addAdults.type') || get(errors, 'addChildren.type')
        ) && (
          <Text fontSize="150" color="danger">
            {get(errors, 'addAdults.message') ||
              get(errors, 'addChildren.message')}
          </Text>
        )}
        {maxAllowedCapacity < DEFAULT_MAX_PEOPLE && (
          <Text fontSize="150" color="danger">
            There are only {maxAllowedCapacity} spots left!
          </Text>
        )}

        <Text fontSize="150">
          {booking.eventType !== eventTypes.KidsDIY &&
            booking.eventType !== eventTypes.AdultDIY && (
              <b>
                * Please only book for those who are participating in this
                workshop. You may book a place for up to{' '}
                {typicalAllowedCapacity} people, including kids.
              </b>
            )}

          {booking.eventType === eventTypes.KidsDIY && (
            <span>
              <b>
                * Children must be accompanied by a parent / guardian. Care for
                children is the responsibility of the child's parent or guardian
                during the workshop.
              </b>{' '}
              Please remain accessible to the team member running the workshop
              and remain in-store.
            </span>
          )}

          {booking.eventType === eventTypes.AdultDIY && (
            <b>
              Please Note: Adult D.I.Y. Workshops are only suitable for
              customers 16 and over. If younger children accompany you to the
              workshop, they are the responsibility of the parent/guardian and
              must be supervised at all times.
            </b>
          )}
        </Text>
        <Privacy />

        <Button
          isLoading={isSubmitting}
          palette="secondary"
          alignSelf="flex-end"
          type="submit"
          float="right"
          minWidth="12rem"
        >
          Book my place
        </Button>
      </FieldStack>
    </Flex>
  )
}

export default RegisterForm
