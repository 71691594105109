import { useHistory, useParams } from 'react-router-dom'
import {
  Card,
  Image as BumbagImage,
  Flex,
  Stack,
  Text,
  Box,
  Heading,
  Button,
  styled,
} from 'bumbag'
import { Site, SpaceBookingsQuery } from 'generated'
import { formateDateMatch } from 'utils/formatDate'
import { media } from 'utils/style-utils'
import { isEmpty, truncate } from 'lodash'
import { getUrlParams } from 'utils/urlParams'
import { useEventsActions } from '../context/Events'
import isFull from 'utils/isFull'
import formatImageUri from 'utils/formatImageUri'

const Image = styled(BumbagImage)`
  height: 100%;
`

const ImgContainer = styled(Box)`
  width: 20rem;
  height: 20rem;
  margin-right: 1rem;
  ${media.phone`
    margin:auto;
    padding-bottom: 1rem;
  `};
`

const Container = styled(Flex)`
  align-items: center;
  ${media.phone`
    flex-wrap: wrap;
  `};
`

const Footer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  ${media.phone`
    flex-direction: column;
  `};
`

const BookButton = styled(Button)`
  min-width: 12rem;
  ${media.phone`
    margin-top: 2rem;
  `};
`

function Address({ site }: { site: Site }) {
  if (site.address) {
    return (
      <Text.Block use="strong">
        {site.address.streetAddress}, {site.address.locality},{' '}
        {site.address.country}, {site.address.postcode}
      </Text.Block>
    )
  }

  return (
    <Text>
      {site.name}, {site?.attributes?.postCode}
    </Text>
  )
}

function BookingTeaser({
  booking,
  bookings,
  site,
}: {
  booking: SpaceBookingsQuery['findBookingsUsingEndDate'][0]
  bookings: SpaceBookingsQuery['findBookingsUsingEndDate']
  site?: Site
}) {
  const { projectId } = useParams<{ projectId: string }>()
  const history = useHistory()
  const { setEvents } = useEventsActions()

  const handleButtonClick = () => {
    setEvents(bookings)
    const url = `/${projectId}/register/${booking.bookingId}${getUrlParams()}`
    history.push(url)
  }

  const full = !bookings.some(
    b =>
      !isFull({
        capacity: b.capacity,
        registeredCount: b.registeredCount,
      })
  )

  const featureImageUri = formatImageUri(
    booking.featureImageUri || 'https://via.placeholder.com/150',
    projectId
  )

  return (
    <Card
      marginBottom="major-4"
      maxWidth="65rem"
      variant="shadowed"
      padding="0"
    >
      <Container>
        <ImgContainer>
          <Image src={featureImageUri} />
        </ImgContainer>
        <Stack width="47rem" padding="1.5rem">
          <Heading use="h4">
            {isEmpty(booking.title) ? booking.eventType : booking.title}{' '}
            {full ? ' -  Full' : ''}
          </Heading>
          {site && <Address site={site} />}
          <Text.Block>
            {truncate(booking.description || '', { length: 200 }) ||
              'No description.'}
          </Text.Block>
          <Footer>
            {booking.start && booking.end && (
              <Text.Block use="strong" marginRight="major-2">
                {formateDateMatch({
                  firstDate: booking.start,
                  secondDate: booking.end,
                  timezone: site?.timezone,
                })}
                {bookings.length > 1 &&
                  `, and ${bookings.length - 1} other events`}
              </Text.Block>
            )}
            <BookButton palette="secondary" onClick={handleButtonClick}>
              {'Book my place'}
            </BookButton>
          </Footer>
        </Stack>
      </Container>
    </Card>
  )
}

export default BookingTeaser
