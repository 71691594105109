import { useParams, Link } from 'react-router-dom'
import { Button } from 'bumbag'
import PageWrapper from 'components/PageWrapper'
import EnquiryForm from 'components/enquiryForm'

function Enquiry() {
  const { projectId } = useParams<{
    projectId: string
  }>()
  return (
    <PageWrapper title={'Enquiry'}>
      <Link to={`/${projectId}`} style={{ textDecoration: 'none' }}>
        <Button variant="link" iconBefore="solid-arrow-left">
          Back
        </Button>
      </Link>
      <EnquiryForm />
    </PageWrapper>
  )
}

export default Enquiry
