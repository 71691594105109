export const getUrlParams = () => {
  let currentUrlParams = new URLSearchParams(window.location.search)
  if (!currentUrlParams.toString()) return ''
  return `?${currentUrlParams.toString()}`
}

export const getWorkshopTypeFromParam = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const type = urlParams.get('workshopType')
  if (!type) return
  return type
}
