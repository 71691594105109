import { useState, useEffect, PropsWithChildren } from 'react'
import { Provider as BumbagProvider } from 'bumbag'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import useApollo from 'lib/useApollo'
// @ts-ignore
import { getTheme } from '@localz/localz-bumbag-theme'
import Home from 'containers/Home'
import Register from 'containers/Register'
import Enquiry from 'containers/Enquiry'
import NotFound from 'containers/NotFound'
import routes from 'constants/routes'
import FullScreenLoading from 'components/FullScreenLoading'
import { SitesProvider } from 'context/Sites'
import { EventsProvider } from './context/Events'
import Unregister from 'containers/Unregister'

function WithProviders({ children }: { children: JSX.Element }) {
  const { projectId } = useParams<{ projectId: string }>()

  const client = useApollo({
    projectId,
  })

  return (
    <ApolloProvider client={client}>
      <WithBumbagProvider>
        <SitesProvider>
          <EventsProvider>{children}</EventsProvider>
        </SitesProvider>
      </WithBumbagProvider>
    </ApolloProvider>
  )
}

function WithBumbagProvider({ children }: PropsWithChildren<{}>) {
  const [theme, setTheme] = useState<any>()
  const { projectId } = useParams<{ projectId: string }>()

  useEffect(() => {
    async function fetchProjectData() {
      const t = await getTheme({
        projectId,
        isDev: process.env.REACT_APP_IS_DEV,
        app: 'dashboard',
      })

      setTheme(t)
    }

    fetchProjectData()
  }, [projectId])

  if (!theme) {
    return <FullScreenLoading />
  }

  return (
    <BumbagProvider
      theme={{
        ...theme,
        modes: {
          enableLocalStorage: false,
        },
      }}
    >
      {children}
    </BumbagProvider>
  )
}

function App() {
  return (
    <Router>
      <Switch>
        <Route
          path={routes.HOME} // /:projectId
        >
          <WithProviders>
            <>
              <Route
                path={routes.HOME} // /:projectId
                exact
                component={Home}
              />
              <Route
                path={routes.ENQUIRY} // /:projectId/enquiry
                exact
                component={Enquiry}
              />
              <Route
                path={routes.REGISTER} // /:projectId/:bookingId/register
                exact
                component={Register}
              />
              <Route
                path={routes.UNREGISTER} // /:projectId/unregister
                exact
                component={Unregister}
              />
            </>
          </WithProviders>
        </Route>
        <Route path="/">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
