import React from 'react'
import { Text } from 'bumbag'
import RegisterScenario from './RegisterScenario'

export function UnregisterSuccess() {
  return (
    <RegisterScenario title={'You have been unregistered'}>
      <Text width="100%">We hope you can join next time!</Text>
    </RegisterScenario>
  )
}
export default UnregisterSuccess
