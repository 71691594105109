import { useCallback } from 'react'
import { AnySchema } from 'yup'
import Lazy from 'yup/lib/Lazy'
import { OptionalObjectSchema } from 'yup/lib/object'
import Reference from 'yup/lib/Reference'

function useYupValidationResolver(
  validationSchema: OptionalObjectSchema<
    Record<
      string,
      AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>
    >
  >
) {
  return useCallback(
    async (data, context) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
          context,
        })

        return {
          values,
          errors: {},
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any[], currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        }
      }
    },
    [validationSchema]
  )
}

export default useYupValidationResolver
