import { InputField, Text } from 'bumbag'
import { Controller, Control } from 'react-hook-form'

export const ErrorMessage = ({ err }: { err?: string }) =>
  err ? (
    <Text fontSize="150" color="danger">
      {err}
    </Text>
  ) : null

const Input = ({
  err,
  control,
  placeholder,
  label,
  name,
  type,
  isRequired = true,
}: {
  err?: string
  control: Control<{}>
  placeholder?: string
  label: string
  name: string
  type?: string
  isRequired?: boolean
}) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ value, onChange }) => (
          <InputField
            placeholder={placeholder}
            defaultValue=""
            label={label}
            isRequired={isRequired}
            value={value}
            autoComplete={name}
            onChange={onChange}
            state={err ? 'danger' : undefined}
            type={type}
          />
        )}
      />
      <ErrorMessage err={err} />
    </>
  )
}
export default Input
