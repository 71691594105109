import React from 'react'

const BunningsLogo = () => {
  return (
    <svg width="141" height="28" viewBox="0 0 141 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.9604 11.4617C20.0058 10.4327 20.6556 8.99061 20.6556 7.39575C20.6556 4.26617 18.1543 1.75 15.0694 1.75H4.92969V21.5683L18.7393 11.6864V16.6016L6.94942 25.0374H15.8542C15.8542 25.0374 18.8396 22.9677 19.8455 22.2482C21.4199 21.1124 22.4685 19.2381 22.4685 17.1185C22.4685 14.6269 21.0362 12.4732 18.9604 11.4617Z"
            fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M116.548 15.3398V18.5058L121.423 18.4987C120.501 20.4062 118.569 21.7193 116.334 21.7193C113.207 21.7193 110.671 19.1469 110.671 15.9754C110.671 12.8023 113.207 10.2299 116.334 10.2299C118.762 10.2299 120.82 11.7797 121.638 13.9571L124.523 11.3626C122.91 8.45301 119.856 6.48535 116.334 6.48535C111.161 6.48535 106.967 10.7333 106.967 15.9754C106.967 21.2159 111.161 25.4639 116.334 25.4639C121.508 25.4639 125.701 21.2159 125.701 15.9754C125.701 15.7562 125.691 15.5591 125.68 15.3374L116.548 15.3398Z"
            fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M100.588 6.7915V17.2638L87.607 6.7915L87.5983 25.0371H91.4001V14.5608L104.399 25.049L104.389 6.7915H100.588Z"
            fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M74.4526 6.79126V17.2636L61.472 6.79126L61.4625 25.0368H65.2643V14.5606L78.2631 25.0487L78.2544 6.79126H74.4526Z"
            fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M80.9958 25.0368H84.7984V6.79126H81.0053L80.9958 25.0368Z"
            fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M35.2688 18.1809C35.2688 20.0354 33.5814 21.5368 31.7544 21.5368C29.9265 21.5368 28.2392 20.0354 28.2392 18.1809L28.2408 6.79126H24.4484L24.439 18.1579C24.439 22.1408 27.8278 25.3677 31.7544 25.3677C35.6809 25.3677 39.0682 22.136 39.0682 18.1532V6.79126H35.2759L35.2688 18.1809Z"
            fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M54.8898 6.7915V17.2638L41.9092 6.7915L41.9005 25.0371H45.7023V14.5608L58.7018 25.049L58.6924 6.7915H54.8898Z"
            fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M130.806 11.9867C130.806 10.6871 131.773 10.0491 132.828 10.0491C133.918 10.0491 134.965 10.5153 135.806 11.4469L137.94 8.72498C136.663 7.37468 134.956 6.53174 132.627 6.53174C129.658 6.53174 127.251 8.97351 127.251 11.9867C127.251 17.1338 134.935 17.2074 134.935 20.0742C134.935 21.4301 133.919 22.1986 132.895 22.1986C131.135 22.1986 129.869 21.2298 129.07 20.4193L126.98 23.0962C128.257 24.4465 130.369 25.5047 132.834 25.5047C135.803 25.5047 138.372 23.0629 138.372 20.0513C138.372 14.472 130.806 14.8456 130.806 11.9867Z"
            fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2811 6.54004L2.62744 11.9732V16.8844L17.199 6.54004H10.2811Z"
            fill="#DA291C" />
    </svg>
  )
}
export default BunningsLogo
