import { useEffect } from 'react'
import { PageWithHeader, TopNav, Box, usePage, Container } from 'bumbag'
import { useLocation } from 'react-router-dom'
import BunningsLogo from './logos/BunningsLogo'

interface Props {
  children: any
  title: string
}

function useQuery() {
  const search = new URLSearchParams(useLocation().search)

  return {
    showNav: search.get('showNav') === 'true',
  }
}

function PageWrapper({ children, title }: Props) {
  const page = usePage()
  const { showNav } = useQuery()

  useEffect(() => {
    document.title = title
    // Hide the head if in an iFrame
    if (!showNav) {
      page.header.close()
    }
  }, [page.header, showNav, title])

  return (
    <PageWithHeader
      header={
        <TopNav backgroundColor="#0d5257">
          <TopNav.Section>
            <TopNav.Item marginLeft="1rem">
              <BunningsLogo />
            </TopNav.Item>
          </TopNav.Section>
        </TopNav>
      }
    >
      <Box use="main" padding="major-2">
        <Container breakpoint="desktop">{children}</Container>
      </Box>
    </PageWithHeader>
  )
}
export default PageWrapper
