import { useCallback } from 'react'
import { chunk, filter } from 'lodash'
import {
  SelectMenu,
  Label,
  Box,
  BoxProps,
  styled,
  palette,
  LabelProps,
} from 'bumbag'

const ITEMS_PER_PAGE = 10

type Option = { key: string; label: string; value: string }

export const StyledAsterisk = styled.span`
  color: ${palette('danger')};
`

function RegionSelect({
  currentRegion,
  onSelect,
  regions,
  loading,
  containerProps,
  state,
  required,
  labelProps,
  disableClear,
}: {
  currentRegion: string | null
  onSelect: Function
  regions: string[]
  loading: boolean
  containerProps?: BoxProps
  state?: string
  required?: boolean
  labelProps?: LabelProps
  disableClear?: boolean
}) {
  const loadOptions = useCallback(
    async ({
      page,
      searchText,
      variables,
    }: {
      page?: number
      searchText?: string
      variables?: any
    }) => {
      const { regionsVariable } = variables
      const sortedRegions = regionsVariable.sort()
      const allOptions = sortedRegions.map((region: string) => ({
        key: region,
        label: region,
        value: region,
      })) as Option[]
      const searchRegExp = new RegExp(searchText!, 'i')
      const fuzzyFind = (option: any) => searchRegExp.test(option.label)
      const searchFilteredOptions = filter(allOptions, fuzzyFind)
      const pagedOptions = chunk(searchFilteredOptions, ITEMS_PER_PAGE)
      return { options: pagedOptions[page! - 1] }
    },
    []
  )

  const onChange = (option: Option) => {
    if (!option) {
      return onSelect(null)
    }
    onSelect(option.value)
  }

  return (
    <Box {...containerProps}>
      <Label htmlFor="select-regions" {...labelProps}>
        State {required && <StyledAsterisk>*</StyledAsterisk>}
      </Label>
      <SelectMenu
        defer
        cacheKey="regions"
        pagination
        id="select-regions"
        hasSearch
        disableClear={disableClear}
        label="State"
        onChange={(e: any) => onChange(e)}
        loadVariables={{ regionsVariable: regions }}
        loadOptions={loadOptions}
        placeholder="Select state"
        // @ts-ignore
        value={
          currentRegion && {
            key: currentRegion,
            value: currentRegion,
            label: currentRegion,
          }
        }
        isLoading={loading}
        state={state}
      />
    </Box>
  )
}

export default RegionSelect
