import {
  PAY_SERVER_AU,
  PAY_SERVER_EU,
  PAY_SERVER_US
} from 'constants/endpoints';

export const REGION_EU = '3000';
export const REGION_US = '9000';
export const REGION_AU = '6000';

function getEndpoint({ projectId }: { projectId: string }) {
  if (!projectId) return PAY_SERVER_AU;

  const region = projectId.slice(-4);

  if (region === REGION_AU) {
    return PAY_SERVER_AU;
  }

  if (region === REGION_EU) {
    return PAY_SERVER_EU;
  }

  if (region === REGION_US) {
    return PAY_SERVER_US;
  }

  return PAY_SERVER_AU;
}

export default getEndpoint;
